import React, { useEffect } from "react";
import { RenderedAssetQueryData, ScoreState } from "~/operations";
import { PolicyQueryAssessmentResults } from "~/components/policy-query-assessment-results";
import { Code } from "~/components/code";

export const useFormattedAssetQuery = (
  renderedData: RenderedAssetQueryData | null,
  scoreState?: ScoreState,
) => {
  const [results, setResults] = React.useState<React.ReactNode | undefined>(
    undefined,
  );
  const { data, assessment } = renderedData || {};

  const getStatusFromScoreState = () => {
    switch (scoreState) {
      case ScoreState.Open:
        return "fail";
      case ScoreState.Closed:
        return "excellent";
      default:
        return "fail";
    }
  };

  useEffect(() => {
    getResults();
  }, [renderedData]);

  const getResults = () => {
    let results = undefined;
    if (assessment) {
      results = (
        <PolicyQueryAssessmentResults
          assessment={assessment}
          status={getStatusFromScoreState()}
        />
      );
    } else if (data) {
      try {
        results = (
          <Code className="plaintext" ContainerProps={{ sx: { p: 0 } }}>
            {JSON.stringify(data, null, 2)}
          </Code>
        );
      } catch {
        results = (
          <Code className="plaintext" ContainerProps={{ sx: { p: 0 } }}>
            An error occurred while processing results
          </Code>
        );
      }
    }

    setResults(results);
  };

  return { results };
};
