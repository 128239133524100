import { MoreHoriz, ContentCopy, Link } from "@mui/icons-material";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButtonProps,
} from "@mui/material";
import { useState } from "react";
import { EmailIcon } from "~/components/icons";
import { copyToClipboard } from "~/lib/clipboard";

type ShareFindingProps = {
  IconButtonProps?: IconButtonProps;
  title?: string;
};

export function TriageBarActionButtons({
  IconButtonProps,
  title,
}: ShareFindingProps) {
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const shareOpen = Boolean(shareAnchorEl);

  const shareLink = window.location.href;
  const shareEmailLink = `mailto:hello@mondoo.com?subject=${encodeURIComponent("Take a look at this finding")}&body=${encodeURIComponent(shareLink)}`;

  const handleCopyTitle = () => {
    if (title) {
      copyToClipboard(title);
    }
  };

  return (
    <>
      <Tooltip title="More actions" placement="top" arrow>
        <IconButton
          onClick={(e) => setShareAnchorEl(e.currentTarget)}
          {...IconButtonProps}
        >
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={shareAnchorEl}
        open={shareOpen}
        onClose={() => setShareAnchorEl(null)}
        onClick={() => setShareAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {title && (
          <MenuItem onClick={handleCopyTitle}>
            <ListItemIcon>
              <ContentCopy fontSize="inherit" />
            </ListItemIcon>
            <ListItemText>Copy title</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => copyToClipboard(shareLink)}>
          <ListItemIcon>
            <Link fontSize="inherit" />
          </ListItemIcon>
          <ListItemText>Copy link</ListItemText>
        </MenuItem>
        <MenuItem component="a" href={shareEmailLink}>
          <ListItemIcon>
            <EmailIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText>Email</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
