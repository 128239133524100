import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";
import { CheckFindingsCheckNode } from "~/pages/inventory/asset/types";
import { Header } from "~/types/table";

export type FindingContext = "advisory" | "cve" | "check" | "imminent_exposure";

const AdvisoryFindingsTableHeaders = (
  hasPermission: boolean,
  assetVulnerabilitiesTableHeaders: any,
): Header[] => {
  return [
    ...(hasPermission ? [assetVulnerabilitiesTableHeaders.SELECT] : []),
    assetVulnerabilitiesTableHeaders.RISK_VALUE,
    assetVulnerabilitiesTableHeaders.ID,
    assetVulnerabilitiesTableHeaders.RISKFACTORS,
    assetVulnerabilitiesTableHeaders.PUBLISHED_DATE,
  ].filter(Boolean);
};

const VulnerabilitiesFindingsTableHeaders = (
  hasPermission: boolean,
  assetVulnerabilitiesTableHeaders: any,
): Header[] => {
  return [
    ...(hasPermission ? [assetVulnerabilitiesTableHeaders.SELECT] : []),
    assetVulnerabilitiesTableHeaders.RISK_VALUE,
    assetVulnerabilitiesTableHeaders.VULNERABILITY_ID,
    assetVulnerabilitiesTableHeaders.RISKFACTORS,
    assetVulnerabilitiesTableHeaders.PUBLISHED_DATE,
  ].filter(Boolean);
};

const ImminentExposuresFindingsTableHeaders = (
  hasPermission: boolean,
  assetVulnerabilitiesTableHeaders: any,
): Header[] => {
  return [
    ...(hasPermission ? [assetVulnerabilitiesTableHeaders.SELECT] : []),
    assetVulnerabilitiesTableHeaders.RISK_VALUE,
    assetVulnerabilitiesTableHeaders.VULNERABILITY_ID,
    assetVulnerabilitiesTableHeaders.RISKFACTORS,
    assetVulnerabilitiesTableHeaders.TRIAGE_BUTTON,
  ].filter(Boolean);
};

const ChecksFindingsTableHeaders = (
  hasPermission: boolean,
  assetVulnerabilitiesTableHeaders: any,
): Header[] => {
  return [
    ...(hasPermission ? [assetVulnerabilitiesTableHeaders.SELECT] : []),
    assetVulnerabilitiesTableHeaders.RISK_VALUE,
    assetVulnerabilitiesTableHeaders.CHECK_ID,
    assetVulnerabilitiesTableHeaders.RISKFACTORS,
  ].filter(Boolean);
};

export const getFindingTableHeaders = (
  context: FindingContext,
  hasPermission: boolean,
  assetVulnerabilitiesTableHeaders: any,
): Header[] => {
  switch (context) {
    case "advisory":
      return AdvisoryFindingsTableHeaders(
        hasPermission,
        assetVulnerabilitiesTableHeaders,
      );
    case "cve":
      return VulnerabilitiesFindingsTableHeaders(
        hasPermission,
        assetVulnerabilitiesTableHeaders,
      );
    case "imminent_exposure":
      return ImminentExposuresFindingsTableHeaders(
        hasPermission,
        assetVulnerabilitiesTableHeaders,
      );
    case "check":
      return ChecksFindingsTableHeaders(
        hasPermission,
        assetVulnerabilitiesTableHeaders,
      );
    default:
      return [];
  }
};

interface FindingsTableRowHrefProps {
  context: FindingContext;
  assetId: string | undefined;
  scopeParams: URLSearchParams | undefined;
}

export const findingsTableRowHrefBuilder = ({
  context,
  assetId,
  scopeParams,
}: FindingsTableRowHrefProps) => {
  switch (context) {
    case "advisory":
      return (
        finding:
          | AdvisoryFindingsNode
          | CVEFindingsNode
          | CheckFindingsCheckNode,
      ) =>
        `/space/inventory/${assetId}/advisories/${finding.id}?${scopeParams}`;
    case "cve":
      return (
        finding:
          | CVEFindingsNode
          | AdvisoryFindingsNode
          | CheckFindingsCheckNode,
      ) =>
        `/space/inventory/${assetId}/vulnerabilities/${finding.id}?${scopeParams}`;
    case "check":
      return (
        finding:
          | CVEFindingsNode
          | AdvisoryFindingsNode
          | CheckFindingsCheckNode,
      ) =>
        `/space/inventory/${assetId}/check?checkMrn=${encodeURIComponent(finding.mrn)}&${scopeParams}`;
    case "imminent_exposure":
      return (
        finding:
          | CVEFindingsNode
          | AdvisoryFindingsNode
          | CheckFindingsCheckNode,
      ) => `/space/vulns/cve/${finding.id}?${scopeParams}`;
    default:
      return () => "#";
  }
};

interface FindingsTableRowExceptionHrefProps {
  exceptionId: string;
  assetId: string | undefined;
  scopeId: string | undefined;
}

export const findingTableRowExceptionHrefBuilder = ({
  exceptionId,
  assetId,
  scopeId,
}: FindingsTableRowExceptionHrefProps) => {
  return `/space/inventory/${assetId}/exceptions?spaceId=${scopeId}&exceptionId=${exceptionId}`;
};
