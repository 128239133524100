import { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid2 as Grid,
  Link,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Org } from "~/lib/types";
import { Loading, LoadingFailedPage } from "~/components/loading";
import { SpaceItemCard } from "~/components/space-cards";
import {
  TestIamActionsQuery,
  useLoadOrganizationStatsQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import { IamActions } from "~/lib/iam";
import { AddButton } from "~/components/add-button";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { FilterBar } from "../compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { EmptyState } from "~/components/empty-state/empty-state";
import { Metrics } from "~/utils/arrow";
import { setDocumentTitle } from "~/utils/commonUtils";

export type OrganizationSpacesPageProps = {
  org: Org;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function OrganizationSpacesPage({
  org,
  availablePermissions,
}: OrganizationSpacesPageProps) {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  const { searchFilters, handleFilterQuery, handleClearSearch } = useSearch();
  const { data, loading, error, fetchMore } = useLoadOrganizationStatsQuery({
    variables: {
      mrn: org.mrn,
      query: searchFilters.join(" "),
      metricMrns: [Metrics.AssetsRiskRatingDistribution],
    },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <Loading what="organization" />;
  }

  if (error || !data?.organization) {
    return <LoadingFailedPage what="organization" />;
  }

  const { pageInfo, edges } = data.organization.spacesList || {};

  const hasCreateSpacePermission = availablePermissions.includes(
    IamActions.CAPTAIN_CREATESPACE,
  );

  const breadcrumbs = [
    <Link
      key="/organization/overview"
      component={RouterLink}
      to={`/organization/overview?organizationId=${org.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="/organization/spaces">Spaces</Typography>,
  ];

  setDocumentTitle(["Spaces", org.name]);

  const hasSpace = org.spacesCount !== 0;
  const noSpaceExistOnFilter =
    edges.length === 0 && Boolean(searchFilters?.length);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {/* Heading */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Grid size={12}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            Spaces
          </Typography>
        </Grid>
        {hasCreateSpacePermission && (
          <Grid size={12}>
            <AddButton
              id="space"
              href={`/organization/spaces/new?organizationId=${org.id}`}
            />
          </Grid>
        )}
      </Box>
      {hasSpace && (
        <Grid size={{ xs: 12 }}>
          <FilterBar
            searchId="org-spaces-search"
            placeholder="organization_spaces"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
          />
        </Grid>
      )}
      {!hasSpace && (
        <Grid size={{ xs: 12 }}>
          <EmptyState contentType="organization-spaces" />
        </Grid>
      )}
      {noSpaceExistOnFilter && (
        <Grid size={{ xs: 12 }}>
          <EmptyState contentType="organization-spaces-filtered">
            <Button
              onClick={() => handleClearSearch()}
              variant="outlined"
              sx={{
                mt: 3,
                borderColor: "secondary.light",
                color: "secondary.light",
              }}
            >
              Clear All Filters
            </Button>
          </EmptyState>
        </Grid>
      )}
      <Grid container spacing={3} mb={3}>
        {edges.slice(pageItems.from, pageItems.to).map((edge) => {
          const entry = {
            org,
            space: edge.node,
            id: org.id + "\x00" + edge.node.id,
            href: `/space/overview?spaceId=${edge.node.id}`,
          };
          return (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={entry.id}>
              <SpaceItemCard entry={entry} />
            </Grid>
          );
        })}
      </Grid>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        totalCount={data.organization.spacesList.totalCount || 0}
        setPageItems={setPageItems}
        customPageSizes={[12, 24, 48]}
      />
    </Box>
  );
}
