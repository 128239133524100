import { Flex } from "~/components/Flex";
import { RiskFactorItem } from "./RiskFactorsConfigurationForm";
import { useQueryBuilder } from "~/pages/security-model/tag-editor/hooks";
import {
  AddSelectionRow,
  SelectionRow,
} from "~/pages/security-model/tag-editor/SelectionRow";

export type RiskFactorTagEditorProps = {
  fieldId: string;
  riskFactor: RiskFactorItem;
  isReadonly?: boolean;
  showFormActions?: boolean;
};

export const RiskFactorTagEditor = ({
  fieldId,
  isReadonly = false,
  showFormActions,
}: RiskFactorTagEditorProps) => {
  const {
    fields,
    handleRemoveSelection,
    handleAddNewSelection,
    canAddSelection,
  } = useQueryBuilder(fieldId);

  return (
    <Flex flexDirection="column" gap={3}>
      {fields.map((_field, selectionIndex) => (
        <SelectionRow
          fieldId={fieldId}
          selectionIndex={selectionIndex}
          onRemove={() => handleRemoveSelection(selectionIndex)}
          showRemoveSelectionButton={selectionIndex !== 0 && !isReadonly}
          key={_field.id}
          isReadonly={isReadonly}
        />
      ))}
      {fields.length === 0 && (
        <AddSelectionRow
          onClick={handleAddNewSelection}
          disabled={!canAddSelection}
          showFormActions={showFormActions}
        />
      )}
    </Flex>
  );
};
