import { RiskFactorAction, useGetSpaceRiskFactorsQuery } from "~/operations";
import {
  fieldId,
  formatMagnitude,
  getInitialCondition,
  RiskFactorConditionKeyValueField,
  RiskFactorConditionKeyValueOperator,
  RiskFactorConditionOperator,
  RiskFactorsConfigurationForm,
  RiskFactorsConfigurationInput,
} from "./RiskFactorsConfigurationForm";
import { Space } from "~/lib/types";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid2,
  Link,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { docsHref } from "~/lib/docs-href";
import { Loading } from "~/components/loading";
import { useState } from "react";

const breadcrumbs = [
  <Link key="home" component={RouterLink} to="/space/overview" display="flex">
    <HomeIcon fontSize="inherit" />
  </Link>,
  <Typography key="security-model">Security Model</Typography>,
  <Typography key="risk-factors">Risk Factors Configuration</Typography>,
];

type Props = {
  space: Space;
};

export function RiskFactorsConfiguration({ space }: Props) {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const spaceMrn = space?.mrn;

  const { data, loading } = useGetSpaceRiskFactorsQuery({
    variables: { spaceMrn },
    fetchPolicy: "network-only",
  });

  const riskFactors = data?.riskFactors.edges || [];
  // sort the riskFactors so that tagged risk factors are first in the list, High Prio then Low Prio.
  const highPrio = riskFactors.find(
    (riskFactor) => riskFactor.title === "High-priority asset",
  );
  const lowPrio = riskFactors.find(
    (riskFactor) => riskFactor.title === "Low-priority asset",
  );
  if (lowPrio) {
    riskFactors.splice(riskFactors.indexOf(lowPrio), 1);
    //place low priority asset after high priority asset
    riskFactors.unshift(lowPrio);
  }
  if (highPrio) {
    riskFactors.splice(riskFactors.indexOf(highPrio), 1);
    riskFactors.unshift(highPrio);
  }

  const defaultValues =
    riskFactors.reduce<RiskFactorsConfigurationInput>(
      (acc, { mrn, action, magnitude, assetFieldFilters }) => {
        const { value, isToxic } = magnitude;
        const rawMagnitude = formatMagnitude(value);

        const labelConditions =
          assetFieldFilters?.labelBasedFilters?.flatMap((f) => {
            return {
              formKey: RiskFactorConditionKeyValueField.Labels,
              operator: RiskFactorConditionOperator.Or,
              keyValueCondition: {
                field: RiskFactorConditionKeyValueField.Unknown,
                operator: RiskFactorConditionKeyValueOperator.Contains,
                values: [
                  {
                    key: f.key,
                    value: f.value,
                  },
                ],
              },
            };
          }) || [];

        const annotationConditions =
          assetFieldFilters?.annotationBasedFilters?.flatMap((f) => {
            return {
              formKey: RiskFactorConditionKeyValueField.Annotations,
              operator: RiskFactorConditionOperator.Or,
              keyValueCondition: {
                field: RiskFactorConditionKeyValueField.Unknown,
                operator: RiskFactorConditionKeyValueOperator.Contains,
                values: [
                  {
                    key: f.key,
                    value: f.value,
                  },
                ],
              },
            };
          }) || [];

        const conditions = [...labelConditions, ...annotationConditions];

        const isTaggable = assetFieldFilters !== null;

        acc[fieldId(mrn)] = {
          enabled: action === RiskFactorAction.Enable,
          magnitude: value,
          rawMagnitude,
          isToxic,
          selections: isTaggable
            ? [
                {
                  conditions: [
                    ...(conditions.length > 0
                      ? conditions
                      : [getInitialCondition()]),
                  ],
                },
              ]
            : undefined,
        };
        return acc;
      },
      {},
    ) || {};

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid2
        container
        columns={1}
        columnGap={2}
        mt={2}
        mb={4}
        sx={{
          alignItems: {
            xs: "start",
            sm: "center",
          },
        }}
      >
        <Grid2 sx={{ width: "100%" }}>
          <Typography
            component="h1"
            fontWeight="bold"
            sx={{
              fontSize: {
                xs: 22,
                sm: 32,
              },
              mb: 3,
            }}
          >
            Risk Factors Configuration
          </Typography>

          <Grid2
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", md: "flex-start" },
              gap: { xs: 2, md: 0 },
            }}
          >
            <Grid2>
              <Typography variant="body1" gutterBottom>
                Choose which factors affect how Mondoo evaluates your assets'
                risk scores.
              </Typography>
              <Typography
                variant="body1"
                color="warning.main"
                sx={{ fontWeight: 600 }}
              >
                WARNING: Adjusting these risk factors will change the scores on
                your assets.
              </Typography>
              <Typography variant="body1">
                To learn more, read the{" "}
                <Link
                  href={docsHref("/platform/security/customize/risk/")}
                  color="primary"
                  target="_blank"
                >
                  Mondoo documentation
                </Link>
                .
              </Typography>
            </Grid2>
            {!loading && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setShowAdvanced(!showAdvanced)}
                sx={{ alignSelf: { xs: "flex-start", md: "flex-start" } }}
              >
                {showAdvanced ? "Hide" : "Show"} Advanced Settings
              </Button>
            )}
          </Grid2>
        </Grid2>
      </Grid2>

      <Divider
        flexItem
        sx={{ display: { xs: "none", md: "flex" }, my: 1, height: 1 }}
      />

      {loading && (
        <Box mt={8}>
          <Loading what="Risk Factors" />
        </Box>
      )}

      {!loading && riskFactors.length > 0 && (
        <RiskFactorsConfigurationForm
          space={space}
          riskFactors={riskFactors}
          defaultValues={defaultValues}
          showAdvanced={showAdvanced}
        />
      )}
    </Box>
  );
}
