/*
  TODO: we very rarely have used this  beautiful stuff here, only "SpacePagesIntegrations" has been used just in couple of places :(
   We should move it to routes folder and use it
*/

export const SpacePagesOverview = "overview";
export const SpacePagesFleet = "fleet";
export const SpacePagesCicd = "cicd";
export const SpacePagesVulns = "vulns";
export const SpacePagesIntegrations = "integrations";
export const SpacePagesSettings = "settings";
