import { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ObservableQuery } from "@apollo/client";
import { SelectChangeEvent } from "@mui/material";
import { PageInfo } from "~/operations";

export type UsePaginationProps = {
  fetchMore?: ObservableQuery["fetchMore"];
  pageInfo?: Pick<PageInfo, "hasNextPage" | "endCursor">;
  pageSizes: number[];
  totalCount: number;
  isFullTextSearch?: boolean;
  goToFirstPage?: boolean;
  defaultPageSize?: number;
};

export type PaginationRange = {
  from: number;
  to: number;
};

export const DEFAULT_PAGE_SIZE = 10;
export let PAGE_SIZE_PARAM = "pageSize";

export function usePagination({
  fetchMore,
  pageInfo,
  totalCount,
  pageSizes,
  isFullTextSearch,
  goToFirstPage,
  defaultPageSize,
}: UsePaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSizeParam = Number(searchParams.get(PAGE_SIZE_PARAM));
  const isValidPageSize = (size: number) => pageSizes.includes(size);
  const initialPageSize = isValidPageSize(pageSizeParam)
    ? pageSizeParam
    : defaultPageSize && isValidPageSize(defaultPageSize)
      ? defaultPageSize
      : pageSizes[0];
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageData, setPageData] = useState<PaginationRange>({
    from: 0,
    to: pageSize,
  });

  useEffect(() => {
    if (goToFirstPage) {
      setPageNumber(1);
    }
  }, [pageInfo]);

  /*
    When the component mounts, if the user explicitly selected items to view, refetch the first page of data
    This is because the initial fetch only fetches its default amount of items max by default
  */
  useEffect(() => {
    if (fetchMore && searchParams.get(PAGE_SIZE_PARAM)) {
      fetchMore({
        variables: {
          first: pageSize,
          after: null,
        },
      });
    }
  }, [pageSize]);

  useEffect(() => {
    updatePageData();
  }, [pageNumber, pageSize]);

  const updatePageData = useCallback(() => {
    const from = (pageNumber - 1) * pageSize;
    const to = pageNumber * pageSize;
    setPageData({
      from,
      to,
    });
  }, [pageNumber, pageSize]);

  // handle a page size change
  const handleOnChange = (e: SelectChangeEvent) => {
    const nextPageSize = Number(e.target.value);
    if (isValidPageSize(nextPageSize)) {
      setPageSize(nextPageSize);
      setPageNumber(1);
      updateSearchParams(PAGE_SIZE_PARAM, nextPageSize);
    }
  };

  // set search params for the page size but do not overwrite the other search params
  const updateSearchParams = (param: string, value: number) => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(param, value.toString());
    setSearchParams(updatedSearchParams.toString(), { replace: true });
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
    if (fetchMore) {
      fetchMore({
        variables: {
          first: pageSize,
          after: pageInfo?.endCursor,
        },
      });
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return {
    page: {
      size: pageSize,
      number: pageNumber,
      data: pageData,
    },
    handle: {
      next: handleNextPage,
      prev: handlePrevPage,
      onChange: handleOnChange,
    },
    disabled: {
      prev: pageNumber === 1,
      // next is disabled if the number of items left is less than the page size
      next: isFullTextSearch
        ? !pageInfo?.hasNextPage
        : totalCount - pageData.from <= pageSize,
    },
  };
}
