import { Stack, Typography, useTheme, alpha } from "@mui/material";
import { NavDrawerFleetIcon } from "~/components/icons";
import { HubCard } from "~/components/hub-card";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { WarningAmberOutlined as WarningIcon } from "@mui/icons-material";
import { Metrics } from "~/utils/arrow";
import { useMetrics } from "~/hooks";

const getExposureLink = (scope: SpaceOrWorkspaceScope | undefined) =>
  scope ? `/space/dashboard/imminent-exposures?${scope.params}` : undefined;

interface ImminentExposuresProps {
  scope?: SpaceOrWorkspaceScope;
}

export const ImminentExposuresCard = ({ scope }: ImminentExposuresProps) => {
  const theme = useTheme();
  const { imminentExposures, isLoading } = useMetrics({
    entityMrn: scope?.mrn || "",
    metricMrns: [Metrics.ImminentExposures],
  });

  const hasNoExposures = !isLoading && imminentExposures?.exposures_count === 0;
  const hasExposures = !isLoading && imminentExposures?.exposures_count > 0;

  // apply neutral color when exposure count is still loading
  const exposuresCardStyle = {
    "& .MuiPaper-root": {
      border: `2px solid ${hasExposures ? theme.palette.critical?.main : hasNoExposures ? theme.palette.none.main : "0"}`,
      backgroundColor: `${hasExposures ? alpha(theme.palette.critical?.light, 0.09) : hasNoExposures ? alpha(theme.palette.none?.light, 0.09) : ""}`,
    },
  };

  return (
    <HubCard
      loading={isLoading}
      title="IMMINENT EXPOSURES"
      tagline="To avert a likely breach, fix these findings today."
      icon={<WarningIcon />}
      data-testid="imminent-exposures-card"
      to={getExposureLink(scope)}
      sx={exposuresCardStyle}
    >
      <Stack direction="row" gap={3}>
        <Typography
          fontSize={32}
          fontWeight={700}
          data-testid="imminent-exposures-count"
        >
          {imminentExposures?.exposures_count}
        </Typography>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <NavDrawerFleetIcon />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ lineHeight: "40px" }}
            data-testid="imminent-exposures-assets-count"
          >
            {imminentExposures?.assets_count} Assets
          </Typography>
        </Stack>
      </Stack>
    </HubCard>
  );
};

ImminentExposuresCard.displayName = "ImminentExposuresCard";
