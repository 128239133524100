import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type ImminentExposuresBreadcrumbsProps = {
  scope: SpaceOrWorkspaceScope;
};

export function ImminentExposuresBreadcrumbs({
  scope,
}: ImminentExposuresBreadcrumbsProps) {
  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/dashboard"
      component={RouterLink}
      to={`/space/dashboard?${scope.params}`}
      underline="hover"
      color="text.primary"
    >
      Dashboard
    </Link>,
    <Typography key="imminent-exposures" color="text.primary">
      Imminent Exposures
    </Typography>,
  ];

  return (
    <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
      {breadcrumbs}
    </Breadcrumbs>
  );
}
