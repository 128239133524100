import { Link as RouterLink } from "react-router-dom";
import {
  alpha,
  Box,
  Button,
  Divider,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArrowForwardIcon,
  ChevronLeftIcon,
  RemoveCircleOutlineIcon,
} from "~/components/icons";
import { FormatDate } from "~/lib/date";
import { cloneElement, ReactElement, useEffect, useState } from "react";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";
import { Asset } from "~/operations";
import { TriageBarActionButtons } from "~/components/TriageBar/TriageBarActionButtons";
import { TriageModal } from "~/components/TriageBar/TriageModal";
import { setDocumentTitle } from "~/utils/commonUtils";
import { TriageBarSkeleton } from "./TriageBarSkeleton";
import CenterEllipseText from "../CenterEllipseText/CenterEllipseText";
import { useScreenSize } from "~/hooks/DevTools";

export type TriageBarProps = {
  title: string;
  titleIcon?: ReactElement;
  linkBack: string;
  riskFactors?: any;
  subheaders?: any[];
  toolbarItems?: { id: string; component: ReactElement }[];
  triage?: boolean; // Optional prop to show the triage button
  asset?: Asset;
  isLoading: boolean;
  availableRemediations?: {
    ticket: {
      active: boolean;
      onClick?: () => void; // Optional click handler for ticket button
      disabled?: boolean; // Optional prop to disable the button
    };
    exception: {
      active: boolean;
      onClick?: () => void; // Optional click handler for exception button
      disabled?: boolean; // Optional prop to disable the button
      hasException?: boolean; // Optional prop to indicate if an exception exists
      linkToException?: string; // Optional prop to link to the exception
    };
    snooze: {
      active: boolean;
    };
  };
};

const sharedTransitions = {
  transitionDuration: "0.35s",
  transitionTimingFunction: "ease-in-out",
  transformOrigin: "bottom left",
};

export function TriageBar({
  title,
  titleIcon,
  linkBack,
  riskFactors,
  subheaders,
  toolbarItems,
  triage = false,
  asset,
  isLoading,
  availableRemediations,
}: TriageBarProps) {
  if (isLoading) {
    return <TriageBarSkeleton />;
  }

  const [triageModalOpen, setTriageModalOpen] = useState(false);
  const [shrink, setShrink] = useState(false);
  const { exception } = availableRemediations || {};
  const { size } = useScreenSize();

  const handleScroll = () => {
    setShrink(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseTriageModal = () => {
    setTriageModalOpen(false);
  };

  const triageBarTitle = asset ? `${title} on ${asset.name}` : title;

  setDocumentTitle(triageBarTitle);

  return (
    <Grid2
      container
      rowSpacing={2}
      columnSpacing={3}
      sx={{
        position: "sticky",
        top: shrink ? 0 : 64,
        transitionProperty: "top top",
        ...sharedTransitions,
        transformOrigin: "top",
        background: (theme) => theme.palette.background.paper,
        zIndex: 99,
      }}
    >
      <Grid2 container size={12} sx={{ width: 1, mx: "auto", py: 3, px: 5 }}>
        <Grid2
          container
          rowSpacing={1}
          flexDirection="column"
          size={{ xs: 12, md: "grow" }}
        >
          <Grid2 size={12}>
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={1}
              sx={{
                ...sharedTransitions,
                transformProperty: "transform",
                transform: shrink
                  ? "scale(0.7) translateY(40px)"
                  : "translateY(0)",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                component={RouterLink}
                to={linkBack}
                sx={{ width: "100%" }}
              >
                <ChevronLeftIcon
                  fontSize="large"
                  sx={{
                    color: "primary.main",
                  }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  minWidth={0}
                  width="100%"
                >
                  {titleIcon &&
                    cloneElement(titleIcon, { fontSize: "inherit" })}
                  <CenterEllipseText
                    id="triage-bar-title"
                    title={triageBarTitle}
                    TextStyle={{
                      fontSize: size.isSmallerThanLg ? "24px" : "32px",
                      fontWeight: 900,
                      lineHeight: "48px",
                    }}
                  />
                </Stack>
              </Stack>

              {riskFactors && (
                <Box sx={{ pl: 1, pt: 0.75 }}>
                  <RiskFactorsIcons
                    riskFactors={riskFactors}
                    shouldLimit={false}
                    showBackgroundColor
                    noWrap
                  />
                </Box>
              )}
            </Stack>
          </Grid2>
          <Grid2
            sx={{
              ".info-block": {
                ...sharedTransitions,
                transitionProperty: "transform translate",
                transform: shrink ? "scale(0.7) translateY(20px)" : "scale(1)",
              },
              ".info-asset-title": { fontWeight: 700 },
              ".info-title": {
                fontWeight: 700,
                color: (theme) => alpha(theme.palette.text.primary, 0.8),
              },
              span: { fontWeight: 400, color: "inherit" },
            }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              columnGap={3}
              className="info-block"
            >
              {/* Subheaders */}
              {subheaders?.map((subheader) => {
                if (subheader.component) {
                  return cloneElement(subheader.component, {
                    key: subheader.id,
                    id: subheader.id,
                  });
                }
                return (
                  <Typography
                    key={subheader.id}
                    id={subheader.id}
                    className="info-title"
                  >
                    {subheader.label}{" "}
                    <Typography component="span">
                      {FormatDate(subheader.value)}
                    </Typography>
                  </Typography>
                );
              })}
            </Stack>
          </Grid2>
        </Grid2>
        {/* No Exceptions or Tickets on CVEs yet */}
        <Grid2
          container
          rowSpacing={1}
          flexDirection="column"
          size={{ xs: 12, md: "auto" }}
        >
          <Grid2 container>
            <Grid2 size={{ xs: "grow" }}>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent={{ xs: "flex-end" }}
                sx={{
                  ...sharedTransitions,
                  transitionProperty: "transform translate",
                  transform: !shrink
                    ? "translateY(0)"
                    : size.isSmallerThanMd
                      ? "translateY(0)"
                      : size.isLargerThanSm
                        ? "translateY(45px)"
                        : "translateY(0)",
                }}
              >
                {toolbarItems?.map((item, index) =>
                  cloneElement(item.component, {
                    key: index,
                  }),
                )}
                <TriageBarActionButtons
                  IconButtonProps={{ sx: { color: "text.primary" } }}
                  title={title}
                />
                {triage && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ display: { xs: "none", md: "flex" }, mx: 1 }}
                  />
                )}
              </Stack>
            </Grid2>
            {/* Triage Button */}
            {triage && (
              <Grid2
                size={{ xs: "auto" }}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  ...(shrink && {
                    ...sharedTransitions,
                    transformOrigin: "bottom right",
                    transform: !shrink
                      ? "translateY(0)"
                      : size.isSmallerThanMd
                        ? "scale(1,.8) translateY(0)"
                        : size.isLargerThanSm
                          ? "scale(1,.8) translateY(45px)"
                          : "translateY(0)",
                  }),
                }}
              >
                <Button
                  onClick={() => setTriageModalOpen(true)}
                  variant="contained"
                  fullWidth
                  sx={(theme) => ({
                    maxWidth: {
                      xs: "100%",
                      sm: theme.spacing(16),
                      md: theme.spacing(32),
                    },
                    minWidth: {
                      xs: "100%",
                      sm: theme.spacing(16),
                      md: theme.spacing(32),
                    },
                    background: `linear-gradient(134deg, #9147FF 0%, #4C35E8 100%)`,
                  })}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.common.white,
                      fontSize: 13,
                      fontWeight: "bold",
                      letterSpacing: ".46px",
                      ...(shrink && {
                        ...sharedTransitions,
                        transformOrigin: "bottom right",
                        transform: "scale(1,1.2) translateY(2px)",
                      }),
                    }}
                  >
                    Take Action
                  </Typography>
                </Button>
              </Grid2>
            )}
          </Grid2>
          {/* no slack integration yet */}
          {exception?.hasException && (
            <ExceptionStatusIndicator
              {...{ linkToException: exception.linkToException, shrink }}
            />
          )}
        </Grid2>
      </Grid2>

      {/* Triage Modal */}
      <TriageModal
        {...{
          triageBarTitle,
          open: triageModalOpen,
          onClose: handleCloseTriageModal,
          availableRemediations,
        }}
      />
    </Grid2>
  );
}

const ExceptionStatusIndicator = ({
  linkToException,
  shrink,
}: {
  linkToException?: string;
  shrink: boolean;
}) => {
  return (
    <Grid2
      sx={{
        ...(shrink && {
          ...sharedTransitions,
          transform: "scale(0.8) translate(94px, 28px)",
        }),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        sx={{ ml: "auto", maxWidth: 256 }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            color: (theme) => alpha(theme.palette.text.primary, 0.8),
          }}
        >
          Status
        </Typography>
        <Button
          {...(linkToException && {
            component: RouterLink,
            to: linkToException,
            endIcon: <ArrowForwardIcon sx={{ color: "secondary.light" }} />,
          })}
          startIcon={<RemoveCircleOutlineIcon />}
          sx={{
            textTransform: "none",
            pr: 0,
            ...(!linkToException && {
              "&:hover": {
                background: "transparent",
                pointerEvents: "none",
              },
            }),
          }}
        >
          Exception created
        </Button>
      </Stack>
    </Grid2>
  );
};
