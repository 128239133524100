import {
  ClientIntegrationType,
  IntegrationType,
  IntegrationTypeSummary,
} from "~/operations";
import { SpaceScope } from "~/hooks/useScope";

export const mapIntegrationType = (type: string) => {
  switch (type.toLowerCase()) {
    case "aws":
      return "aws";
    case "aws_s3":
      return "amazons3";
    case "crowdstrike_falcon":
      return "crowdstrike";
    case "s3":
      return "amazon-s3-compatible";
    case "k8s":
      return "kubernetes";
    case "managed_client":
      return "managed";
    case "microsoft_defender":
      return "defender";
    case "sentinel_one":
      return "sentinelone";
    case "ticket_system_jira":
      return "ticket_system_jira";
    case "ticket_system_email":
      return "ticket_system_email";
    case "ticket_system_zendesk":
      return "ticket_system_zendesk";
    case "ticket_system_github":
      return "ticket_system_github";
    case "ticket_system_gitlab":
      return "ticket_system_gitlab";
    case "ticket_system_azure_devops":
      return "ticket_system_azure_devops";
    default:
      return type.toLowerCase();
  }
};

export const integrationDetailHref = (
  type: ClientIntegrationType,
  mrn: string,
  space: SpaceScope,
) => {
  const id = mrn.split("/").pop();
  return `/space/integrations/${encodeURIComponent(mapIntegrationType(type))}/${id}?${space.params}`;
};

// TODO: this should be returned from the server
export const formatIntegrationName = (type: string) => {
  switch (type.toLowerCase()) {
    case "aws":
      return "AWS";
    case "azure":
      return "Azure";
    case "azure_blob":
      return "Azure Blob Storage";
    case "bigquery":
      return "BigQuery";
    case "crowdstrike_falcon":
      return "CrowdStrike";
    case "snowflake":
      return "Snowflake";
    case "aws_s3":
      return "Amazon S3";
    case "s3":
      return "S3 Compatible Services";
    case "gcp":
      return "Google Cloud Platform";
    case "gcs_bucket":
      return "Google Cloud Storage Bucket";
    case "gitlab":
      return "GitLab";
    case "github":
      return "GitHub";
    case "host":
      return "Domain/IP Address";
    case "k8s":
      return "Kubernetes";
    case "ms365":
      return "Microsoft 365";
    case "okta":
      return "Okta";
    case "oci":
      return "Oracle Cloud Infrastructure";
    case "google_workspace":
      return "Google Workspace";
    case "managed_client":
      return "Managed Clients";
    case "microsoft_defender":
      return "Microsoft Defender for Cloud";
    case "postgres":
      return "PostgreSQL";
    case "hosted_slack":
      return "Slack";
    case "slack":
      return "Slack Notifications";
    case "shodan":
      return "Shodan";
    case "sentinel_one":
      return "SentinelOne";
    case "msteams":
      return "Microsoft Teams Notifications";
    case "telegram":
      return "Telegram Notifications";
    case "httppost":
      return "HTTP Webhook Notifications";
    case "ticket_system_jira":
      return "Atlassian Jira";
    case "ticket_system_email":
      return "Ticketing via email";
    case "ticket_system_zendesk":
      return "Zendesk";
    case "ticket_system_github":
      return "GitHub Issues";
    case "ticket_system_gitlab":
      return "GitLab Issues";
    case "ticket_system_azure_devops":
      return "Azure DevOps";
    default:
      return type.toLowerCase();
  }
};

export const chatIntegrations = ["msteams", "slack", "telegram", "httppost"];

export const isChat = (type: string) =>
  chatIntegrations.some((x) => x === type.toLowerCase());

export const getTagline = (type: string) => {
  if (isChat(type)) return "Chat Ops";

  switch (type.toLowerCase()) {
    case "aws":
    case "azure":
    case "gcp":
    case "oci":
      return "Cloud Hosting";
    case "github":
    case "gitlab":
    case "google_workspace":
    case "hosted_slack":
    case "ms365":
    case "okta":
      return "SaaS Platform";
    default:
      return "";
  }
};

export const integrationTypes = [
  IntegrationType.Httppost,
  IntegrationType.K8S,
  IntegrationType.ManagedClient,
  IntegrationType.Msteams,
  IntegrationType.Slack,
  IntegrationType.Telegram,
  IntegrationType.Azure,
  IntegrationType.AzureBlob,
  IntegrationType.CrowdstrikeFalcon,
  IntegrationType.Gcp,
  IntegrationType.Ms365,
  IntegrationType.MicrosoftDefender,
  IntegrationType.Okta,
  IntegrationType.GoogleWorkspace,
  IntegrationType.Bigquery,
  IntegrationType.Snowflake,
  IntegrationType.SentinelOne,
  IntegrationType.S3,
  IntegrationType.AwsS3,
  IntegrationType.Gitlab,
  IntegrationType.Github,
  IntegrationType.HostedSlack,
  IntegrationType.GcsBucket,
  IntegrationType.Postgres,
  IntegrationType.TicketSystemJira,
  IntegrationType.TicketSystemEmail,
  IntegrationType.TicketSystemZendesk,
  IntegrationType.TicketSystemGithub,
  IntegrationType.TicketSystemGitlab,
  IntegrationType.TicketSystemAzureDevops,
  IntegrationType.Oci,
  IntegrationType.Host,
  IntegrationType.Shodan,
];

type ActiveSummary = IntegrationTypeSummary;
type PartialActiveSummary = Partial<ActiveSummary> & {
  __typename: "IntegrationTypeSummary";
  type: IntegrationType;
};

const fillSummary = (summary: PartialActiveSummary): ActiveSummary => {
  return {
    ...summary,
    activeCount: summary.activeCount || 0,
    assetsCount: summary.assetsCount || 0,
    checksCount: summary.checksCount || 0,
    failedCount: summary.failedCount || 0,
    policiesCount: summary.policiesCount || 0,
    warningCount: summary.warningCount || 0,
    totalCount: summary.totalCount || 0,
  };
};

export const activeSummaries = (
  summaries?: PartialActiveSummary[] | null,
): ActiveSummary[] => {
  const hasIntegrations = (v: PartialActiveSummary) =>
    v.totalCount && v.totalCount > 0;

  const active =
    summaries
      ?.filter((v) => integrationTypes.includes(v.type) && hasIntegrations(v))
      .map((s) => fillSummary(s)) || [];

  // Aws and AwsHosted are separate integration types
  // but are presented as a single integration type 😩
  const awsSummaries =
    summaries?.filter((x) =>
      [IntegrationType.Aws, IntegrationType.AwsHosted].includes(x.type),
    ) || [];

  const awsSummary = awsSummaries.reduce<ActiveSummary>(
    (acc, curr) => {
      acc.activeCount += curr.activeCount || 0;
      acc.assetsCount += curr.assetsCount || 0;
      acc.checksCount += curr.checksCount || 0;
      acc.failedCount += curr.failedCount || 0;
      acc.warningCount += curr.warningCount || 0;
      acc.policiesCount += curr.policiesCount || 0;
      acc.totalCount += curr.totalCount || 0;
      return acc;
    },
    {
      __typename: "IntegrationTypeSummary",
      type: IntegrationType.Aws,
      activeCount: 0,
      assetsCount: 0,
      checksCount: 0,
      failedCount: 0,
      warningCount: 0,
      policiesCount: 0,
      totalCount: 0,
    },
  );

  if (hasIntegrations(awsSummary)) {
    active.unshift(awsSummary);
  }

  return active.sort((a, b) => {
    return (
      b.totalCount - a.totalCount ||
      formatIntegrationName(a.type).localeCompare(formatIntegrationName(b.type))
    );
  });
};
