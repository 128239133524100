export function useGeneratedCode() {
  // generate a filename that should sort properly on the file system
  function makeFileName(): string {
    const now = new Date();
    const fileDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60 * 1000,
    )
      .toISOString()
      .split("T")[0];
    return `generated-policy-${fileDate}.yaml`;
  }

  // download code as a yaml file
  const handleDownload = (code: string | null) => {
    if (!code) return;
    const blob = new Blob([code], { type: "text/yaml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    a.download = makeFileName();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return { makeFileName, handleDownload };
}
