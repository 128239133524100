import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { Loading, LoadingFailedPage } from "~/components/loading";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  CaseStatus,
  DocumentType,
  FindingType,
  GetAggregateScoresDocument,
  GetCasesCountDocument,
  ScoreRating,
  ScoreStateFilter,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useGetCasesCountQuery,
  useGetTicketingIntegrationsQuery,
  useLoadAdvisoryQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import {
  CveRiskFactors,
  Sources,
  SourcesProps,
} from "~/components/vulnerabilities";
import { AdvisoryContentDetails } from "~/components/vulnerabilities/advisory-content-details";
import { useInventory } from "./inventory/hooks/useInventory";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { FindingRemediation } from "~/components/vulnerabilities/finding-remediation";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { useCases } from "~/components/cases/hooks";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { CreateCasesIntegrationModal } from "~/components/cases/components/CreateCasesIntegrationModal";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { ScrollToTop } from "~/lib/scroll-to-top";
import {
  AffectedAssetsAdapter,
  AssetContextualLinkType,
} from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { ScoreBlock } from "./space/security/components/Check/ScoreBlock";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { ChangeEvent, Fragment } from "react";
import { DetailPageTopSection } from "~/components/DetailPageLayouts/DetailPageTopSection";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { isFeatureEnabled } from "~/login/features";
import { GenerateReportButton } from "~/pages/compliance/components/generate-report/GenerateReportButton";
import { InventoryPageHeader } from "~/routes";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { setDocumentTitle } from "~/utils/commonUtils";
import { FindingRemediationV2 } from "~/components/vulnerabilities/finding-remediationV2";

export type SpaceAdvisoryPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const SpaceAdvisoryPage = ({
  space,
  scope,
  availablePermissions,
}: SpaceAdvisoryPageProps) => {
  const { id = "" } = useParams();
  const [searchParams] = useSearchParams();
  const { assetsTotalCount } = useInventory({ scope });

  const {
    data,
    loading: advisoryLoading,
    error,
  } = useLoadAdvisoryQuery({
    variables: { id },
  });

  const { data: aggScoreData, loading: aggScoreLoading } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: scope.mrn || "",
        filter: {
          findingMrn: data?.advisory?.mrn,
        },
      },
      skip: !data?.advisory?.mrn || !scope.mrn,
    });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: String(data?.advisory?.mrn || ""),
    scoreType: AggregateScoreType.Advisory,
  });

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalClose,
    handleSetException,
    handleRemoveException,
    handleSetExceptionModalOpen,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedEntities([]);
    },
    onRemoveException: () => {
      setSelectedEntities([]);
    },
    scopeMrns: [space.mrn],
    advisoryMrns: [data?.advisory?.mrn || ""],
    applyToCves: true,
    refetchQueries: [GetAggregateScoresDocument],
  });

  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCase,
    hasCreateCasePermissions,
    hasIntegrationCreatePermission,
    hasListCasePermissions,
    loading: createCaseLoading,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions,
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: scope.mrn,
        findingMrns: [data?.advisory?.mrn as string],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !data?.advisory?.mrn || !hasListCasePermissions,
  });

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasCreateCasePermissions,
  });

  const {
    handleNodeClick,
    handleCancelClick,
    selectedEntities,
    setSelectedEntities,
  } = useExceptionsSelection();

  const loading = advisoryLoading || aggScoreLoading || riskFactorsLoading;

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Loading what="Advisory" />
      </Box>
    );
  }

  const advisory = data?.advisory;
  const aggScore =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  // When no aggregateScore exists, we fallback cvssScore of the Advisory.
  const cvssScore = aggScore?.cvss || advisory?.cvssScore;

  if (error || !advisory) {
    return <LoadingFailedPage what="Advisory" />;
  }

  const sourceLinks: SourcesProps["links"] = (advisory?.externalUrls || [])
    .flatMap((link) => link ?? [])
    .map((reference) => ({
      href: reference.url,
      text: reference.title || "Advisory Source Link",
      icon: reference.iconIDEnum,
    }));

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/vulns"
      component={RouterLink}
      to={`/space/findings/advisories?${scope.params}`}
      display="flex"
    >
      Advisories
    </Link>,
    <Typography key={"/space/vulns/advisory/:advisory"}>
      {advisory.title}
    </Typography>,
  ];

  const totalScanned = assetsTotalCount;
  const totalAffected = aggScore?.blastRadius?.affected || 0;

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Exposed",
      value: totalAffected < 0 ? "---" : totalAffected.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    // {
    //   label: "Updated",
    //   count: -1, // TODO
    //   icon: <ZoomOutMap fontSize="inherit" />,
    // },
  ];

  const hasTicketingIntegration =
    (ticketingIntegrationsData?.ticketingIntegrations?.integrations?.length ||
      0) > 0;

  setDocumentTitle([advisory.title, "Advisories"]);

  return (
    <>
      <ScrollToTop />
      <DetailPageTopSection
        content={{
          breadcrumbs,
          triageBar: {
            title: advisory.id || `Advisory ${advisory.id}`,
            linkBack: `/space/findings/advisories?${scope.params}`,
            riskFactors,
            triage: true,
            subheaders: [
              {
                id: "created",
                label: "Created at",
                value: String(advisory.publishedAt),
              },
              {
                id: "last-modified",
                label: "Last modified",
                value: String(advisory.modifiedAt),
              },
            ],
            toolbarItems: [
              {
                id: "generate-report",
                component: (
                  <Fragment>
                    {isFeatureEnabled("Reporting") && (
                      <GenerateReportButton
                        documentType={DocumentType.AdvisoryReport}
                        title={advisory.title || "Generated Advisory Report"}
                        space={space}
                        advisoryId={advisory.id}
                        IconProps={{ sx: { color: "text.primary" } }}
                      />
                    )}
                  </Fragment>
                ),
              },
            ],
            isLoading: loading,
            availableRemediations: {
              ticket: {
                active: true,
                onClick: hasTicketingIntegration
                  ? () => {
                      setSelectedEntities([
                        {
                          mrn: advisory?.mrn,
                          scopeMrn: scope.mrn,
                          groupId: "ALL_ASSETS",
                        },
                      ]);
                      handleCreateCaseModalOpen();
                    }
                  : handleCreateCaseIntegrationModalOpen,
              },
              exception: {
                active:
                  isFeatureEnabled("Space level exceptions") &&
                  scope.type === ScopeType.Space,
                hasException: !!aggScore?.exception,
                onClick: !!aggScore?.exception
                  ? handleRemoveException
                  : () => {
                      setSelectedEntities([
                        {
                          mrn: advisory?.mrn || "",
                          exception: aggScore?.exception,
                          groupId: "SCOPE_ADVISORY",
                        },
                      ]);
                      handleSetExceptionModalOpen();
                    },
                disabled: exceptionsLoading,
              },
              snooze: { active: false },
            },
          },
          ...(hasListCasePermissions &&
            (casesData?.cases.totalCount || 0 > 0) && {
              casesSummary: (
                <CasesSummary
                  count={casesData?.cases.totalCount || 0}
                  scope={scope}
                />
              ),
            }),
          summary: advisory.description,
          stats,
          right: (
            <ScoreBlock
              hasScore={Boolean(aggScore)}
              mainScore={aggScore?.riskValue}
              cvssScore={cvssScore}
              epssScore={aggScore?.epss}
              blastRadius={aggScore?.blastRadius}
              riskFactors={aggScore?.riskFactors}
              rating={aggScore?.rating || ScoreRating.None}
              hasError={
                Boolean(aggScore) && aggScore?.__typename !== "AggregateScore"
              }
            />
          ),
          left: {
            lower: <Sources id="advisory-sources" links={sourceLinks} />,
          },
        }}
      />
      <InventoryPageHeader>
        <Box id="risk-factors" className="section">
          <SectionHeading heading="Vulnerability insights" />
          <Box className="section-content">
            <CveRiskFactors
              id="cve-risk-factors"
              cvssScore={cvssScore}
              riskFactors={riskFactorsWithDocs}
            />
          </Box>
        </Box>

        <AdvisoryContentDetails advisoryId={advisory.id} scope={scope} />

        {isFeatureEnabled("Remediation V2") ? (
          <FindingRemediationV2 findingId={advisory.id} scopeMrn={scope.mrn} />
        ) : (
          <FindingRemediation findingId={advisory.id} scopeMrn={scope.mrn} />
        )}

        <AffectedAssetsAdapter
          scope={scope}
          contextId={advisory.id}
          filter={{
            mrn: advisory?.mrn,
            types: [FindingType.Advisory],
            state: ScoreStateFilter.Open,
          }}
          urlContextType={AssetContextualLinkType.Advisory}
          emptyStateMessage="There are currently no exposed assets for this advisory."
          selectionProps={{
            selectedEntities: selectedEntities,
            hasSelectPermissions: hasCreateCasePermissions,
            onCheckboxClick: handleNodeClick,
            getIsRowSelected: (rowAssetMrn) => {
              return Boolean(
                selectedEntities.find(
                  (asset) => asset.scopeMrn === rowAssetMrn,
                ),
              );
            },
            onCheckAll: (checked, edges) => {
              if (checked) {
                setSelectedEntities(
                  (edges || []).map((edge) => ({
                    groupId: "",
                    scopeMrn: edge?.mrn || "",
                    mrn: advisory?.mrn,
                    exception: null,
                  })),
                );
              } else {
                setSelectedEntities([]);
              }
            },
            onCheckboxChange: async (
              e: ChangeEvent<HTMLInputElement>,
              checked: boolean,
              asset,
            ) => {
              const nextSelected = selectedEntities.filter(
                (s) => s.scopeMrn !== asset?.mrn,
              );
              if (checked) {
                nextSelected.push({
                  groupId: "",
                  mrn: advisory?.mrn,
                  scopeMrn: asset?.mrn || "",
                  exception: null,
                });
              }

              setSelectedEntities(nextSelected);
            },
            renderToolbar: (totalCount) => {
              return (
                selectedEntities.filter(
                  (a) =>
                    a.groupId !== "ALL_ASSETS" &&
                    a.groupId !== "SCOPE_ADVISORY",
                ).length > 0 && (
                  <ExceptionsToolbar
                    target="asset"
                    onCreateCaseClick={
                      hasTicketingIntegration
                        ? hasCreateCasePermissions
                          ? handleCreateCaseModalOpen
                          : undefined
                        : handleCreateCaseIntegrationModalOpen
                    }
                    onCancel={handleCancelClick}
                    selectedEntities={selectedEntities}
                    totalCount={totalCount}
                  />
                )
              );
            },
          }}
        />

        {
          <>
            <CreateCasesIntegrationModal
              open={isCreateCaseIntegrationModalOpen}
              onClose={handleCreateCaseIntegrationModalClose}
              space={space}
              redirectTo={`${location.pathname}?${searchParams}`}
              hasIntegrationCreatePermission={hasIntegrationCreatePermission}
            />
            <CreateCaseModal
              open={isCreateCaseModalOpen}
              target="asset"
              loading={advisoryLoading || createCaseLoading}
              onClose={() => {
                handleCreateCaseModalClose();
                setSelectedEntities([]);
              }}
              onSave={handleCreateCase}
              selectedAssets={selectedEntities}
              space={space}
              scope={scope}
              hasCreateCasePermissions={hasCreateCasePermissions}
            />
            <ExceptionsModals
              isSetExceptionModalOpen={isSettingException}
              isRemoveExceptionModalOpen={isRemovingException}
              onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
              onSetExceptionModalClose={handleSetExceptionModalClose}
              onSetExceptionModalSave={handleSetException}
              onRemoveExceptionModalSave={handleRemoveException}
              loading={exceptionsLoading}
              target="advisory"
              role="security"
              exceptionGroups={[]}
              selectedEntities={selectedEntities}
            />
          </>
        }
      </InventoryPageHeader>
    </>
  );
};
