import { Fragment } from "react";
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Asset } from "~/pages/inventory/asset/types";
import { configurationTableSx } from "./asset-configuration-section";
import { OpenInNewIcon, WarningAmberIcon } from "./icons";
import { CopyText } from "./CopyText";
import { SpaceScope } from "~/hooks/useScope";
import { AssetIntegration } from "~/pages/inventory/asset";
import { IamActions } from "~/lib/iam";
import { isExternalLink } from "~/lib/isExternalLink";
import {
  createAssetOverviewTimeValue,
  createAssetOverviewUrlValue,
  extractAssetKey,
  extractAssetLink,
  extractAssetValue,
  isLastScanOlderThanSevenDays,
} from "~/utils/assetUtils";
import { IGNORE_LABELS } from "../../constants";

type AssetOverviewGroup = Asset["overviewGroups"][0];

type OverviewGroups = {
  labelsGroup: AssetOverviewGroup[];
  otherGroups: AssetOverviewGroup[];
  externalLink?: AssetOverviewUrValue;
};

type AssetOverviewItem = AssetOverviewGroup["items"][0];

type AssetOverviewUrValue = Extract<
  AssetOverviewItem["values"][0],
  { __typename: "AssetOverviewURLValue" }
>;

export type ConfigurationAssetOverviewProps = {
  asset: Asset;
  integration?: AssetIntegration;
  space: SpaceScope;
};

export function ConfigurationAssetOverview({
  asset,
  integration,
  space,
}: ConfigurationAssetOverviewProps) {
  const overviewGroups = asset.overviewGroups.filter(
    (g) => g.title !== "Platform overview",
  );

  const hasIntegrationUpdatePermission = space.iamActions.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  );

  const { labelsGroup, externalLink, otherGroups } =
    asset.overviewGroups.reduce<OverviewGroups>(
      (acc, overviewGroup) => {
        const items = [...overviewGroup.items];
        const group = { ...overviewGroup, items };

        if (
          group.title.toLowerCase().includes("labels") ||
          group.title.toLowerCase().includes("tags")
        ) {
          acc.labelsGroup.push(group);
        } else if (group.key === "external-link") {
          if (group.items.length === 1 && group.items[0].values.length === 1) {
            const value = group.items[0].values[0];
            if (value.__typename === "AssetOverviewURLValue") {
              acc.externalLink = value;
            }
          }
        } else {
          if (group.key === "asset-overview-scan-overview-group") {
            // Ideally scan overview group would already include scan time values.
            // We're inserting them ourselves for now.
            // https://github.com/mondoohq/planning/issues/208
            const firstScanItem = asset.createdAt
              ? createAssetOverviewTimeValue(
                  "first-scanned-at",
                  "First scanned at",
                  asset.createdAt,
                )
              : undefined;

            const lastScanItem = asset.updatedAt
              ? createAssetOverviewTimeValue(
                  "last-scanned-at",
                  "Last scanned at",
                  asset.updatedAt,
                )
              : undefined;

            // Integration overview item doesn't have all necessary info for required features.
            // We're augmenting it ourselves for now.
            // https://github.com/mondoohq/planning/issues/235
            const integrationRefItem = items.find(
              (item) => item.key === "integration",
            );
            const integrationUrlItem =
              integrationRefItem && integration
                ? createAssetOverviewUrlValue(
                    "integration",
                    "Integration",
                    integration.href,
                    integration.name,
                  )
                : undefined;

            if (integrationRefItem && integrationUrlItem) {
              // replace the ref item with url item
              items.splice(
                items.indexOf(integrationRefItem),
                1,
                integrationUrlItem,
              );
            }

            group.items = [firstScanItem, lastScanItem, ...items].flatMap(
              (i) => i ?? [],
            );
            acc.otherGroups.push(group);
          }
        }
        return acc;
      },
      { labelsGroup: [], externalLink: undefined, otherGroups: [] },
    );

  const assetLabels = asset.labels.filter(
    (label) => !IGNORE_LABELS.includes(label.key),
  );

  if (labelsGroup.length === 0 && assetLabels.length > 0) {
    const items = assetLabels.map((label) => {
      return {
        key: label.key,
        value: label.value,
      };
    });

    labelsGroup.push({
      title: "labels",
      key: "labels",
      items: [
        {
          key: "labels",
          name: "labels",
          sources: null,
          values: [
            {
              dictValue: items,
              __typename: "AssetOverviewDictValue",
            },
          ],
        },
      ] as AssetOverviewItem[],
      __typename: "AssetOverviewGroup",
    });
  }

  const filteredLabelsGroup = labelsGroup.map((group) => ({
    ...group,
    items: group.items.map((groupItem) => ({
      ...groupItem,
      values: groupItem.values.map((v) => {
        if (v.__typename !== "AssetOverviewDictValue") return null;

        return {
          ...v,
          dictValue: v.dictValue?.filter((dv) => {
            return !IGNORE_LABELS.includes(dv.key);
          }),
        };
      }),
    })),
  }));

  const overviewPolicyResults = (
    <TableContainer>
      <Table
        sx={{ tableLayout: "fixed", "& tr:hover": { background: "inherit" } }}
      >
        {otherGroups.map((group) => {
          return (
            <Fragment key={group.key}>
              <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      pointerEvents: "none",
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      pl: 0,
                      pb: 1,
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.background.lightest}`,
                      textTransform: "uppercase",
                    }}
                    className="table-title"
                  >
                    {group.title}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={configurationTableSx}>
                {group?.items.map((item) => {
                  const key = extractAssetKey(item);
                  const value = extractAssetValue(item);
                  const link =
                    key !== "Integration" || hasIntegrationUpdatePermission
                      ? extractAssetLink(
                          item,
                          space.params.toString(),
                          hasIntegrationUpdatePermission,
                        )
                      : undefined;

                  const isLastScanDate = key === "Last scanned at";
                  const showWarning =
                    isLastScanDate && isLastScanOlderThanSevenDays(value);

                  return (
                    <Fragment key={item.key}>
                      <TableRow>
                        <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                          {key}
                        </TableCell>
                        {link && (
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <CopyText value={value}>
                              <Link
                                target={
                                  isExternalLink(link) ? "_blank" : "_self"
                                }
                                rel="noreferrer"
                                to={link}
                                component={RouterLink}
                              >
                                {value}
                              </Link>
                            </CopyText>
                          </TableCell>
                        )}

                        {!link && (
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            {showWarning && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: {
                                    xs: "center",
                                    sm: "flex-start",
                                  },
                                }}
                              >
                                <Typography
                                  component="span"
                                  sx={{ color: "warning.main" }}
                                >
                                  {value}
                                </Typography>
                                <Tooltip
                                  title="This scan ran more than a week ago. It may not reflect the asset's current state."
                                  arrow
                                  placement="top"
                                >
                                  <WarningAmberIcon
                                    sx={{
                                      ml: 1,
                                      fontSize: 20,
                                      color: "warning.main",
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            )}

                            {!showWarning && <CopyText>{value}</CopyText>}
                          </TableCell>
                        )}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          );
        })}

        {filteredLabelsGroup.map((group) => {
          const groupData = group.items[0];
          // After filtering out tags/labels in the UI we might end up having
          // an edge case that we don't have anymore labels/tags left.
          // In this case we don't wanna display the Tags/Labels section at all.
          if (groupData.values.length === 0) return;

          const value = groupData.values[0];
          const dictValue =
            value?.__typename === "AssetOverviewDictValue"
              ? value.dictValue
              : undefined;

          return (
            <Fragment key={group.key}>
              <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      pointerEvents: "none",
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      pl: 0,
                      pb: 1,
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.background.lightest}`,
                      textTransform: "uppercase",
                    }}
                  >
                    {group.title}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={configurationTableSx}>
                {dictValue?.map((value) => {
                  return (
                    <Fragment key={value.key}>
                      <TableRow>
                        <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                          {value.key}
                        </TableCell>
                        <Tooltip title={value.value} placement="top" arrow>
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <CopyText>{value.value}</CopyText>
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          );
        })}

        {externalLink && (
          <TableBody sx={configurationTableSx}>
            <TableRow>
              <TableCell className={"width-100"} sx={{ pl: 0 }}>
                <Button
                  href={externalLink.url}
                  target="_blank"
                  variant="contained"
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                >
                  {externalLink.displayValue}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  const overviewPolicyNoResults = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 2,
        pt: 2,
      }}
    >
      <Typography variant="body2" color="text.secondary">
        No Data
      </Typography>
    </Box>
  );

  return (
    <Box>
      {overviewGroups.length > 0 || labelsGroup.length > 0
        ? overviewPolicyResults
        : overviewPolicyNoResults}
    </Box>
  );
}
