import React, { useEffect } from "react";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { RemediationScripts } from "~/components/DetailsPage/components/Remediation/RemediationScripts";
import { useRemediationFilter } from "~/components/DetailsPage/components/Remediation/hooks/useRemediationFilter";
import { RemediationFilter } from "~/components/DetailsPage/components/Remediation/RemediationFilter";
import {
  RemediationPackageFieldsFragment,
  useGetRemediationLazyQuery,
} from "~/operations";
import { EmptySection } from "~/components/vulnerabilities/empty-section";
import { SectionHeading } from "~/components/DetailsPage/components";
import { TabOption, TabsVariant } from "~/components/ui-library";
import { useSections } from "~/hooks/useSections";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { RemediationFilterSkeleton } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationFilterSkeleton";
import { TabVariantSkeleton } from "~/components/ui-library/components/atoms/TabsVariant/TabVariantSkeleton";
import { AnimatePresence } from "framer-motion";
import { isFeatureEnabled } from "~/login/features";

type PkgsByPlatform = {
  [platform: string]: RemediationPackageFieldsFragment[];
};

export type Package = {
  name: string;
  arch?: string | null;
  version?: string | null;
};

export type FindingFixedByProps = {
  scopeMrn: string | undefined;
  findingId: string;
};

export function FindingRemediation({
  scopeMrn,
  findingId,
}: FindingFixedByProps) {
  const [fetchRemediation, { data, loading }] = useGetRemediationLazyQuery();

  const { filter: packageFilter, handleFilter: handlePackageFilter } =
    useRemediationFilter({
      defaultValue: "",
      filterKey: "mrn",
    });

  const { filter: platformFilter, handleFilter: handlePlatformFilter } =
    useRemediationFilter({
      defaultValue: "",
      filterKey: "platform",
    });

  const remediation =
    data?.remediation?.__typename === "VulnerabilityRemediationResponse"
      ? data?.remediation
      : undefined;

  const remediationPackages = remediation?.packages || [];
  const remediationScripts = remediation?.scripts || [];
  const namespaces = remediation?.namespaces || [];
  const highestVersionNamespace = remediation?.highestVersionEcosystem || "";

  const platformTabs: TabOption[] = namespaces
    .slice()
    .sort()
    .map((platform) => ({
      label: platform,
      value: platform,
    }));

  const pkgsByArch = remediationPackages.reduce<PkgsByPlatform>((acc, pkg) => {
    const arch = pkg.arch || "unspecified architecture";
    const archPkgs = acc[arch] || [];
    archPkgs.push(pkg);
    acc[arch] = archPkgs;
    return acc;
  }, {});

  const defaultArch =
    Object.keys(pkgsByArch)
      .slice()
      .sort(([a], [b]) => a.localeCompare(b))?.[0] ||
    "unspecified architecture";

  const sections = useSections({
    key: "remediationArch",
    sections: Object.keys(pkgsByArch),
    withLocalStorage: false,
  });

  const entries = Object.entries(pkgsByArch)
    .slice()
    .sort(([a], [b]) => a.localeCompare(b));

  const isGroupSelected = Object.keys(pkgsByArch).includes(packageFilter);
  const packagesToRemediate = isGroupSelected
    ? pkgsByArch[packageFilter]
    : remediationPackages.filter(
        (p) =>
          p.id === packageFilter || (!packageFilter && p.arch === defaultArch),
      );

  useEffect(() => {
    if (!scopeMrn || !findingId) return;

    fetchRemediation({
      variables: {
        input: {
          vulnerabilityId: findingId,
          scopeMrn,
          filter: {
            ...(platformFilter ? { namespace: platformFilter } : {}),
            filterAffected: isFeatureEnabled(
              "Remediation Affected Assets Filter",
            ),
          },
          packages: packagesToRemediate.map((p) => ({
            name: p.name,
            fixedVersion: p.version,
          })),
        },
      },
    }).then(() => {
      if (packageFilter) {
        if (isGroupSelected) {
          !sections.isOpen(packageFilter) && sections.toggle(packageFilter);
        } else {
          const arch =
            packagesToRemediate.find((p) => p.id === packageFilter)?.arch || "";

          !sections.isOpen(arch) && sections.toggle(arch);
        }
      } else {
        !sections.isOpen(defaultArch) && sections.toggle(defaultArch);
      }
    });
  }, [findingId, packageFilter, platformFilter, scopeMrn]);

  const isRemediationNotFound =
    data?.remediation?.__typename === "VulnerabilityRemediationNotFound";
  const isRemediationNotSupported =
    data?.remediation?.__typename === "VulnerabilityRemediationNotSupported";
  const isRemediationUnknown =
    data?.remediation?.__typename === "VulnerabilityRemediationUnknown";
  const isRemediationEmpty =
    data?.remediation?.__typename !== "VulnerabilityRemediationResponse";

  const handleChangePlatform = (value: string) => {
    handlePlatformFilter(value);
  };

  return (
    <Box id="remediation">
      <SectionHeading heading="Remediation" />
      {isRemediationEmpty ? (
        <>
          {isRemediationNotFound && (
            <EmptySection
              id="software-remediation-empty"
              text="There is currently no software to remediate."
            />
          )}
          {isRemediationNotSupported && (
            <EmptySection
              id="software-remediation-unsupported"
              text="Remediation for this package is not supported."
            />
          )}
          {isRemediationUnknown && (
            <EmptySection
              id="software-remediation-unknown"
              text="Remediation for this package does not exist yet."
            />
          )}
        </>
      ) : (
        <AnimatePresence>
          {loading && remediationScripts.length === 0 ? (
            <Box mb={3}>
              <TabVariantSkeleton />
            </Box>
          ) : (
            <TabsVariant
              options={platformTabs}
              selectedTab={platformFilter || highestVersionNamespace}
              onTabChange={handleChangePlatform}
              mb={3}
            />
          )}
          <Typography textTransform="uppercase" fontWeight={700} mb={2}>
            Packages contained in this finding
          </Typography>
          <Typography fontWeight={400} mb={1} color="text.secondary">
            Resolve the finding by updating the following packages to the
            indicated version:
          </Typography>
          {loading ? (
            <Box>
              <RemediationFilterSkeleton />
            </Box>
          ) : (
            <>
              {entries.map(([arch, pkgs]) => (
                <Box key={arch}>
                  <Button
                    variant="text"
                    endIcon={
                      sections.isOpen(arch) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    onClick={() => sections.toggle(arch)}
                    sx={{
                      textAlign: "left",
                      ml: -0.5,
                      p: 0.5,
                      ".MuiButton-endIcon": { color: "primary.main" },
                      textTransform: "unset",
                    }}
                  >
                    <Typography fontWeight={700}>{arch}</Typography>
                  </Button>
                  <Collapse in={sections.isOpen(arch)} unmountOnExit>
                    <RemediationFilter
                      onFilter={handlePackageFilter}
                      filter={packageFilter}
                      packages={pkgs}
                      allPackagesCount={pkgs.length}
                      isAllVersionsSelected={
                        packageFilter === arch ||
                        (!packageFilter && defaultArch === arch)
                      }
                      onAllClick={() => {
                        handlePackageFilter(arch);
                      }}
                    />
                  </Collapse>
                </Box>
              ))}
            </>
          )}
          <RemediationScripts scripts={remediationScripts} loading={loading} />
        </AnimatePresence>
      )}
    </Box>
  );
}
