import { Navigate, Route, Routes } from "react-router-dom";
import { Print } from "~/pages/print";
import { Root } from "~/pages/root";
import { SpacePages } from "~/pages/space-pages";
import { OrganizationPages } from "~/pages/organization-pages";
import { UserSettings } from "~/pages/user-settings";
import { Invitations } from "~/pages/invitation";
import { CreateOrganizationPage } from "~/pages/create-new-organization";
import { RegionDashboard } from "~/pages/dashboard-region";
import { SharedSpacesPage } from "~/pages/shared-spaces";
import { PageLayout, Redirect } from "~/routes";
import { AwsWaiter } from "~/pages/aws-guide";
import { NotFoundPage } from "~/pages/not-found";
import {
  EmailPreferenceList,
  useSetEmailPreferenceMutation,
} from "~/operations";
import { FeatureFlags } from "~/login/feature-flags";
import { IconsDiscovery } from "~/pages/icons";
import { isFeatureEnabled } from "~/login/features";

export default function AuthenticatedApp() {
  const [setEmailPreference] = useSetEmailPreferenceMutation();

  // This portion is explicitly for initial signup.  If a user
  // checks the box to opt in to emails, we will set their email preferences
  // here.
  let emailOptIn = localStorage.getItem("mondoo_emailOptIn");
  if (emailOptIn === "true") {
    setEmailPreference({
      variables: {
        input: [
          { listID: EmailPreferenceList.NewsletterEvents, subscribe: true },
          { listID: EmailPreferenceList.NewsletterGeneral, subscribe: true },
          { listID: EmailPreferenceList.NewsletterProduct, subscribe: true },
        ],
      },
    });
    localStorage.removeItem("mondoo_emailOptIn");
  }

  return (
    <Routes>
      <Route element={<Root />}>
        <Route element={<PageLayout />}>
          <Route index element={<RegionDashboard />} />
          <Route path="flags" element={<FeatureFlags />} />
          {isFeatureEnabled("Developer Tools") && (
            <Route path="icons" element={<IconsDiscovery />} />
          )}
          <Route path="dashboard" element={<RegionDashboard />} />
          <Route
            path="organizations/new"
            element={<CreateOrganizationPage />}
          />
          <Route path="spaces" element={<SharedSpacesPage />} />
          <Route path="invitations" element={<Invitations />} />
          <Route path="settings/*" element={<UserSettings />} />
        </Route>
        <Route path="space/*" element={<SpacePages />} />
        <Route path="organization/*" element={<OrganizationPages />} />
      </Route>
      <Route path="print/*" element={<Print />} />
      {/* AWS Campaign */}
      <Route path="aws-guide">
        <Route index element={<AwsWaiter />} />
      </Route>
      {/* Deprecated Routes */}
      <Route path="spaces" element={<Navigate replace to="/dashboard" />} />
      <Route
        path="library/*"
        element={<Redirect from="library" to="vulns" />}
      />
      <Route
        path="space/library/*"
        element={<Redirect from="space/library" to="space/vulns" />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
