import { Flex } from "~/components/Flex";
import { CircularProgress, TextFieldProps, Typography } from "@mui/material";
import { useRef } from "react";
import { ConditionValuesNameType } from "../types";
import { ConditionValue } from "./ConditionValue";
import { ValuesDropdownAutocomplete } from "../autocomplete-dropdown/ValuesDropdownAutocomplete";

export type ConditionValuesProps = {
  values: string[];
  isKeySelected: boolean;
  onValueRemove: (value: string) => void;
  name: ConditionValuesNameType;
  conditionFormDictionaryKey: string;
  options: Array<{ label: string; value: string }>;
  isReadonly: boolean;
  isLoading: boolean;
  isDictionaryKeySelected: boolean;
  onDropdownSearchFieldKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onDropdownSearchFieldChange: TextFieldProps["onChange"];
  isValuesDropdownOpen: boolean;
  onValuesDropdownOpen: () => void;
  onValuesDropdownClose: () => void;
  onItemClick: (value: string) => void;
  handleKeyValueKeyClick: (value: string) => void;
  handleKeyValueValueClick: (value: string) => void;
};

export const ConditionValues = ({
  isKeySelected,
  values,
  onValueRemove,
  name,
  conditionFormDictionaryKey,
  options,
  isReadonly,
  isLoading,
  isDictionaryKeySelected,
  onDropdownSearchFieldKeyUp,
  onDropdownSearchFieldChange,
  onItemClick,
  isValuesDropdownOpen,
  onValuesDropdownOpen,
  onValuesDropdownClose,
  handleKeyValueKeyClick,
  handleKeyValueValueClick,
}: ConditionValuesProps) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Flex
        gap={2}
        alignItems="center"
        flexWrap="wrap"
        flexGrow={1}
        sx={{ position: "relative" }}
        ref={anchorRef}
      >
        {values.map((value, index) => (
          <Flex alignItems="center" gap={2} key={value}>
            <ConditionValue
              // Backend does not support user-friendly labels yet, so for demo purposes we will display value as is
              value={
                isDictionaryKeySelected ? (
                  <Typography>
                    {conditionFormDictionaryKey}:{" "}
                    <Typography
                      component="span"
                      color="text.secondary"
                      fontWeight={600}
                    >
                      {value}
                    </Typography>
                  </Typography>
                ) : (
                  options.find((o) => o.value === value)?.label || value
                )
              }
              onClick={() => onValueRemove(value)}
              isReadonly={isReadonly}
            />
            {index !== values.length - 1 && (
              <Typography fontWeight={700}> OR </Typography>
            )}
          </Flex>
        ))}
        {isKeySelected &&
          !isReadonly &&
          (isLoading ? (
            <CircularProgress />
          ) : (
            <ValuesDropdownAutocomplete
              values={values}
              name={name}
              conditionFormDictionaryKey={conditionFormDictionaryKey}
              options={options}
              anchorRef={anchorRef}
              onDropdownSearchFieldKeyUp={onDropdownSearchFieldKeyUp}
              onDropdownSearchFieldChange={onDropdownSearchFieldChange}
              onItemClick={onItemClick}
              handleKeyValueKeyClick={handleKeyValueKeyClick}
              handleKeyValueValueClick={handleKeyValueValueClick}
              isValuesDropdownOpen={isValuesDropdownOpen}
              isDictionaryKeySelected={isDictionaryKeySelected}
              onValuesDropdownOpen={onValuesDropdownOpen}
              onValuesDropdownClose={onValuesDropdownClose}
            />
          ))}
      </Flex>
    </>
  );
};
