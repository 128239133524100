import { getError } from "~/lib/handle-error";
import { IamActions } from "~/lib/iam";
import {
  AggregateScoreType,
  GetCaseDetailsDocument,
  TestIamActionsQuery,
  useCloseCaseMutation,
  useGetAggregateScoresQuery,
  useGetCaseDetailsQuery,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { AssetsProps } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/types";
import { ObservableQuery } from "@apollo/client";
import { CaseTicket } from "~/components/cases/types";
import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type UseCaseDetailsPageParams = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  scope: SpaceOrWorkspaceScope;
};

export function useCaseDetailsPage({
  availablePermissions,
  scope,
}: UseCaseDetailsPageParams) {
  const [searchParams] = useSearchParams();
  const caseMrn = searchParams.get("caseMrn") || "";
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: caseData,
    loading: caseLoading,
    error: caseError,
    fetchMore,
  } = useGetCaseDetailsQuery({
    variables: {
      mrn: caseMrn,
      mitigatedAssetsFirst: 10,
      affectedAssetsFirst: 10,
    },
    skip: !caseMrn,
  });

  const [closeCase, { loading: closeCaseLoading }] = useCloseCaseMutation({
    refetchQueries: [GetCaseDetailsDocument],
  });

  const caseDetails =
    caseData?.case?.__typename === "Case" ? caseData?.case : undefined;

  const findingMrns =
    (caseDetails?.queryRefs?.length || 0) > 0
      ? caseDetails?.queryRefs
      : caseDetails?.vulnerabilityRefs;

  const findingMrn = findingMrns?.[0]?.findingMrn;

  const { data: aggScoreData, loading: aggScoreLoading } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: scope.mrn,
        filter: {
          findingMrn,
        },
      },
      skip: !caseMrn || !findingMrn,
    });

  const aggregateScores =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores
      : undefined;

  const fetchMoreAffectedAssets: ObservableQuery["fetchMore"] = (params) => {
    const { variables } = params;
    return fetchMore({
      variables: {
        affectedAssetsFirst: variables?.first,
        affectedAssetsAfter: variables?.after,
      },
    });
  };

  const fetchMoreMitigatedAssets: ObservableQuery["fetchMore"] = (params) => {
    const { variables } = params;
    return fetchMore({
      variables: {
        mitigatedAssetsFirst: variables?.first,
        mitigatedAssetsAfter: variables?.after,
      },
    });
  };

  const handleCloseCase = async () => {
    const hasCloseCasePermissions = availablePermissions.includes(
      IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_UPDATECASE,
    );

    if (!hasCloseCasePermissions) return;

    try {
      await closeCase({
        variables: {
          input: {
            mrn: caseMrn,
          },
        },
      });
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to close the case: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  const tickets = (caseDetails?.ticketRefs || []).map<CaseTicket>(
    (ticketRef) => ({
      id: ticketRef.ticketId || "",
      href: ticketRef.url || "",
      type: ticketRef.type,
    }),
  );

  const affectedAssetsConnection =
    caseDetails?.affectedAssets?.__typename === "CaseAssetsConnection"
      ? caseDetails?.affectedAssets
      : undefined;

  const mitigatedAssetsConnection =
    caseDetails?.mitigatedAssets?.__typename === "CaseAssetsConnection"
      ? caseDetails?.mitigatedAssets
      : undefined;

  const containedFinding = aggregateScores?.edges?.[0];

  const affectedAssets =
    (affectedAssetsConnection?.edges || []).flatMap((e) => e.node ?? []) || [];
  const affectedAssetsTotalCount = affectedAssetsConnection?.totalCount || 0;

  const mitigatedAssets =
    (mitigatedAssetsConnection?.edges || []).flatMap((e) => e.node ?? []) || [];
  const mitigatedAssetsTotalCount = mitigatedAssetsConnection?.totalCount || 0;

  const urlContextTypeSoreTypeMap: Partial<Record<AggregateScoreType, string>> =
    {
      [AggregateScoreType.Advisory]: AssetContextualLinkType.Advisory,
      [AggregateScoreType.Check]: AssetContextualLinkType.Check,
      [AggregateScoreType.Vulnerability]: AssetContextualLinkType.Cve,
      [AggregateScoreType.Asset]: AssetContextualLinkType.Asset,
      [AggregateScoreType.Software]: AssetContextualLinkType.Software,
      [AggregateScoreType.VersionedSoftware]: AssetContextualLinkType.Software,
    };

  const scoreType =
    containedFinding?.node?.scoreType || AggregateScoreType.Asset;

  const assetsCommonProps = {
    scope,
    contextId:
      containedFinding?.node?.scoreType === AggregateScoreType.Check
        ? containedFinding?.node?.findingMrn || ""
        : containedFinding?.node?.findingMrn?.split("/").pop() || "",
    urlContextType:
      urlContextTypeSoreTypeMap[scoreType] || AssetContextualLinkType.Asset,
  };

  const affectedAssetsProps: AssetsProps = {
    ...assetsCommonProps,
    assets: affectedAssets,
    totalCount: affectedAssetsTotalCount,
    filteredTotalCount: affectedAssetsTotalCount,
    pageInfo: affectedAssetsConnection?.pageInfo,
    fetchMore: fetchMoreAffectedAssets,
    handleSortClick(_: string): void {},
    orderBy: { direction: "", field: "" },
  };

  const mitigatedAssetsProps: AssetsProps = {
    ...assetsCommonProps,
    assets: mitigatedAssets,
    totalCount: mitigatedAssetsTotalCount,
    filteredTotalCount: mitigatedAssetsTotalCount,
    pageInfo: mitigatedAssetsConnection?.pageInfo,
    fetchMore: fetchMoreMitigatedAssets,
    handleSortClick(_: string): void {},
    orderBy: { direction: "", field: "" },
  };

  return {
    caseDetails,
    totalRefsCount: caseDetails?.totalRefsCount || 0,
    closedRefsCount: caseDetails?.closedRefsCount || 0,
    isLoading: caseLoading || aggScoreLoading,
    isCloseCaseLoading: closeCaseLoading,
    handleCloseCase,
    finding: containedFinding,
    affectedAssetsProps,
    mitigatedAssetsProps,
    tickets,
    caseError,
  };
}
