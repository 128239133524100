import { ListName } from "../types";

const getAuthors = (authors: ListName[] = []): string => {
  // Filter out authors with undefined names and get valid names
  const validNames = authors?.map((author) => author?.name).filter(Boolean);
  const validCount = validNames.length;

  if (validCount === 0) return "";

  // If there is only one valid author, return their name
  if (validCount === 1) return validNames[0];

  // If there are two valid authors, return them separated by "and"
  if (validCount === 2) return validNames.join(" and ");

  // For three or more valid authors, return them separated by commas with "and" before the last one
  return `${validNames.slice(0, -1).join(", ")}, and ${validNames[validCount - 1]}`;
};

export default getAuthors;
