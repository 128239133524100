import { tableFromIPC } from "apache-arrow";
import { ChartData } from "~/components/charts/donut-chart";
import { getColor } from "~/lib/colors";
import { Theme } from "@mui/material";
import { SmallDonutData } from "~/components/SmallDonut";
import { GridData } from "~/pages/space/Dashboards/components/GridSegment/GridSegment";
import { ScoreRating } from "~/operations";
import parseSeconds from "./parseSeconds";

export enum Metrics {
  // provides a distribution of asset by risk rating for a given space
  AssetsRiskRatingDistribution = "//metrics.api.mondoo.app/distribution/assets/risk-rating",
  // provides a count of policies by category for a given asset or space
  PolicyCategoryDistribution = "//metrics.api.mondoo.app/categories/policies",
  // provides a distribution of policy grades for a given asset or space. For spaces, this is the distribution of the average grades
  PolicyGradeDistribution = "//metrics.api.mondoo.app/distribution/policies",
  // provides a distribution of policy grades for a given asset or space. For spaces, this is the distribution of the average grades
  PolicyRiskRatingDistribution = "//metrics.api.mondoo.app/distribution/policies/risk-rating",
  // provides a distribution of check severities for a given asset or space
  ChecksResultDistribution = "//metrics.api.mondoo.app/distribution/checks",
  // provides a distribution of check ratings for a given asset.  Only provides a rating and count
  ChecksRatingCounts = "//metrics.api.mondoo.app/rating/checks",
  // provides a count of vulnerable assets by severity for a given space
  VulnerableAssetsSeverity = "//metrics.api.mondoo.app/rating/assets/vulns",
  // provides a count of vulnerabilities by severity for a given space
  AdvisoriesSeverity = "//metrics.api.mondoo.app/rating/advisories",
  // provides a count of vulnerabilities by severity for a given space
  CVEsSeverity = "//metrics.api.mondoo.app/rating/vulns",
  // provides stats of remediation severities for a given space
  RemediationSeverity = "//metrics.api.mondoo.app/severity/remediation",
  // provides stats of remediation severities for a given space
  MTTRSeverity = "//metrics.api.mondoo.app/severity/remediation",
  // provies SLA stats for mean time to remediation for a given space
  MTTRSla = "//metrics.api.mondoo.app/rating/findings/sla-mttr",
  // provides a count of assets by grade in a given space
  SecurityAssetsStats = "//metrics.api.mondoo.app/severity/assets/security",
  // provide count of assets_count and exposures_count in a given space
  ImminentExposures = "//metrics.api.mondoo.app/imminent-exposures",
}

type PolicyCategory = {
  category: string;
  count: number;
};

type PolicyGrade = {
  grade: "a" | "b" | "c" | "d" | "f" | "errored" | "unrated";
  count: number;
};

type PolicyRiskRating = {
  rating:
    | "critical"
    | "high"
    | "medium"
    | "low"
    | "none"
    | "pass"
    | "snoozed"
    | "disabled"
    | "error";
  count: number;
};

type AssetRiskRating = {
  rating:
    | "critical"
    | "high"
    | "medium"
    | "low"
    | "none"
    | "pass"
    | "snoozed"
    | "disabled"
    | "error"
    | "total";
  count: number;
};

type Severity = "none" | "low" | "medium" | "high" | "critical";

type CheckRatingCount = {
  rating: ScoreRating;
  count: number;
};

type CheckResult = {
  errored: number;
  failing: number;
  passing: number;
  unscored: number;
  skipped: number;
  total: number;
  severity: Severity;
};

enum MetricRatings {
  CRITICAL = "critical",
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  NONE = "none",
  PASS = "pass",
  SNOOZED = "snoozed",
  DISABLED = "disabled",
  ERROR = "error",
}

const defaultCheckResult: Omit<CheckResult, "severity"> = {
  errored: 0,
  failing: 0,
  passing: 0,
  unscored: 0,
  skipped: 0,
  total: 0,
};

type CommonSeverities = {
  rating: MetricRatings;
  count: number;
};

type VulnerableAssetSeverity = CommonSeverities;
type AdvisorySeverity = CommonSeverities;
type CVESeverity = CommonSeverities;

type RemediationSeverity = {
  fixed: number;
  mttr_seconds: number;
  severity: Severity;
  total: number;
};

type MttrSlaRatings = {
  rating: MetricRatings;
  num_nearing_sla_date: number;
  num_past_sla_date: number;
  mttr_seconds: number;
  sla_days: number;
};

export type MttrObject = {
  [category: string]: {
    days: number;
    hours: number;
    minutes: number;
  };
};

export type MttrSla = {
  rating: MetricRatings;
  nearDate: number;
  pastDate: number;
  slaDays: number;
  mttr: {
    days: number;
    hours: number;
    minutes: number;
  };
};

type CheckResultType = {
  errored: number;
  failing: number;
  passing: number;
  severity: Severity;
  skipped: number;
  total: number;
  unscored: number;
};

type imminentExposure = {
  assets_count: number;
  exposures_count: number;
};

function convertArrowStringToArray<T>(arrowString: string): Array<T> {
  const binaryData = Uint8Array.from(atob(arrowString || ""), (c) =>
    c.charCodeAt(0),
  );

  const tableData = tableFromIPC(binaryData).toArray();
  return tableData.map((e) => {
    return e.toJSON();
  });
}

type MetricsEntries = Array<{
  metricMrn: string;
  title: string;
  arrowResult?: string | null;
}>;

export function getBaseMetrics<T>(entries: MetricsEntries, metrics: Metrics) {
  const metricsEntry = entries?.find((m) => m.metricMrn === metrics);

  if (!metricsEntry) return [];

  return convertArrowStringToArray<T>(metricsEntry.arrowResult || "");
}

export function parseAssetRiskRatings(entries: MetricsEntries): SmallDonutData {
  const assetRiskRatingMetrics = getBaseMetrics<AssetRiskRating>(
    entries,
    Metrics.AssetsRiskRatingDistribution,
  );

  const total =
    assetRiskRatingMetrics.find((m) => m.rating === "total")?.count || 0;

  const context = assetRiskRatingMetrics
    .filter((m) => m.rating !== "total")
    .map((m) => {
      return {
        rating: m.rating,
        value: m.count,
      };
    });

  return {
    total,
    context,
  };
}

export function parsePolicyCategories(entries: MetricsEntries) {
  const policyCategories = getBaseMetrics<PolicyCategory>(
    entries,
    Metrics.PolicyCategoryDistribution,
  );

  return policyCategories.map((c) => ({
    title: c.category,
    score: c.count,
  }));
}

export function parsePolicyGrades(
  entries: MetricsEntries,
  theme: Theme,
): Array<ChartData> {
  const policyGradesMetrics = getBaseMetrics<PolicyGrade>(
    entries,
    Metrics.PolicyGradeDistribution,
  );

  return policyGradesMetrics.map((gradeMetrics) => {
    const grade = gradeMetrics.grade.toUpperCase();

    if (grade === "ERRORED") {
      return {
        label: "ERROR",
        value: gradeMetrics.count,
        color: getColor(theme, "error"),
      };
    }

    return {
      label: grade,
      value: gradeMetrics.count,
      color: getColor(theme, grade),
    };
  });
}

export function parsePolicyRiskRatings(
  entries: MetricsEntries,
  theme: Theme,
): SmallDonutData {
  const policyRiskRatingMetrics = getBaseMetrics<PolicyRiskRating>(
    entries,
    Metrics.PolicyRiskRatingDistribution,
  );

  const onlyVulnerablePolicies = policyRiskRatingMetrics.filter(
    (x) => x.rating !== "pass" && x.rating !== "error",
  );

  const total = onlyVulnerablePolicies.reduce(
    (acc, curr) => acc + curr.count,
    0,
  );

  return {
    total,
    context: onlyVulnerablePolicies.map((m) => {
      return {
        rating: m.rating,
        value: m.count,
      };
    }),
  };
}

// currently bring used in Asset Insignts to determine priority findings
function parseChecksRatingCounts(
  entries: MetricsEntries,
): Record<string, number> {
  const checksRatingCounts = getBaseMetrics<CheckRatingCount>(
    entries,
    Metrics.ChecksRatingCounts,
  );

  // reduce the array to a single object with the rating as the key
  return checksRatingCounts.reduce<Record<string, number>>(
    (acc, item) => {
      return {
        ...acc,
        [item.rating]: item.count,
      };
    },
    {
      none: 0,
      low: 0,
      medium: 0,
      high: 0,
      critical: 0,
      snoozed: 0,
      disabled: 0,
      error: 0,
    },
  );
}

function parseChecksResults(
  entries: MetricsEntries,
): Record<Severity, CheckResult> {
  const checkResults = getBaseMetrics<CheckResult>(
    entries,
    Metrics.ChecksResultDistribution,
  );

  return checkResults.reduce<Record<Severity, CheckResult>>(
    (acc, item) => {
      return {
        ...acc,
        [item.severity]: item,
      };
    },
    {
      critical: {
        ...defaultCheckResult,
        severity: "critical",
      },
      high: {
        ...defaultCheckResult,
        severity: "high",
      },
      medium: {
        ...defaultCheckResult,
        severity: "medium",
      },
      low: {
        ...defaultCheckResult,
        severity: "low",
      },
      none: {
        ...defaultCheckResult,
        severity: "none",
      },
    },
  );
}

const filterPassingAndErrorItems = (severities: CommonSeverities[]) => {
  return severities.filter(
    (x) => x.rating !== MetricRatings.PASS && x.rating !== MetricRatings.ERROR,
  );
};

function parseVulnerableAssetsSeverity(
  entries: MetricsEntries,
): SmallDonutData {
  const vulnerableAssetSeverities = getBaseMetrics<VulnerableAssetSeverity>(
    entries,
    Metrics.VulnerableAssetsSeverity,
  );

  // Filter out the "pass" rating because passing assets are not vulnerable
  const onlyVulnerableAssets = filterPassingAndErrorItems(
    vulnerableAssetSeverities,
  );

  const total = onlyVulnerableAssets.reduce((acc, current) => {
    return acc + current.count;
  }, 0);

  return {
    total,
    context: onlyVulnerableAssets.map((s) => ({
      rating: s.rating,
      value: s.count,
    })),
  };
}

function parseAdvisoriesSeverity(entries: MetricsEntries): SmallDonutData {
  const advisoriesSeverities = getBaseMetrics<AdvisorySeverity>(
    entries,
    Metrics.AdvisoriesSeverity,
  );

  // Filter out the "pass" rating because passing assets are not vulnerable
  const onlyVulnerable = filterPassingAndErrorItems(advisoriesSeverities);

  const total = onlyVulnerable.reduce((acc, current) => {
    return acc + current.count;
  }, 0);

  return {
    total,
    context: onlyVulnerable.map((s) => ({
      rating: s.rating,
      value: s.count,
    })),
  };
}

function parseCVEsSeverity(entries: MetricsEntries): SmallDonutData {
  const cvesSeverities = getBaseMetrics<CVESeverity>(
    entries,
    Metrics.CVEsSeverity,
  );

  // Filter out the "pass" rating because passing severities are not vulnerable
  const onlyVulnerable = filterPassingAndErrorItems(cvesSeverities);

  const total = onlyVulnerable.reduce((acc, current) => {
    return acc + current.count;
  }, 0);

  return {
    total,
    context: onlyVulnerable.map((s) => ({
      rating: s.rating,
      value: s.count,
    })),
  };
}

function parseRemediationSeverity(entries: MetricsEntries): Array<GridData> {
  const remediationSeverity = getBaseMetrics<RemediationSeverity>(
    entries,
    Metrics.RemediationSeverity,
  );

  return remediationSeverity.map((c) => ({
    title: c.severity,
    score: c.fixed,
    total: c.total,
  }));
}

function parseMTTRSeverity(entries: MetricsEntries): MttrObject {
  const mttrSeverity = getBaseMetrics<RemediationSeverity>(
    entries,
    Metrics.MTTRSeverity,
  );

  const defaultData: MttrObject = {
    none: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    low: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    medium: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    high: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    critical: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
  };

  return mttrSeverity.reduce((acc, data) => {
    const { days, hours, minutes } = parseSeconds(data.mttr_seconds);

    return {
      ...acc,
      [data.severity]: {
        days,
        hours,
        minutes,
      },
    };
  }, defaultData);
}

function parseMTTRSla(entries: MetricsEntries): MttrSla[] {
  const slaMttr = getBaseMetrics<MttrSlaRatings>(entries, Metrics.MTTRSla);

  return slaMttr.map((sla) => {
    const { days, hours, minutes } = parseSeconds(sla.mttr_seconds);

    return {
      rating: sla.rating,
      nearDate: sla.num_nearing_sla_date,
      pastDate: sla.num_past_sla_date,
      slaDays: sla.sla_days,
      mttr: {
        days,
        hours,
        minutes,
      },
    };
  });
}

export function parseAssetsGrades(
  entries: MetricsEntries,
  theme: Theme,
): Array<ChartData> {
  const assetsGradesMetrics = getBaseMetrics<PolicyGrade>(
    entries,
    Metrics.SecurityAssetsStats,
  );

  return assetsGradesMetrics.map((gradeMetrics) => {
    const grade = gradeMetrics.grade.toUpperCase();

    if (grade === "ERRORED") {
      return {
        label: "ERROR",
        value: gradeMetrics.count,
        color: getColor(theme, "error"),
      };
    }

    return {
      label: grade,
      value: gradeMetrics.count,
      color: getColor(theme, grade),
    };
  });
}

function parsePassedCheckStats(entries: MetricsEntries): Array<GridData> {
  const checksPassedStats = getBaseMetrics<CheckResultType>(
    entries,
    Metrics.ChecksResultDistribution,
  );

  return checksPassedStats
    .filter((c) => c.severity !== "none")
    .map((c) => ({
      title: c.severity,
      score: c.passing,
      total: c.total,
    }));
}

function parseImminentExposures(entries: MetricsEntries): imminentExposure {
  return getBaseMetrics<imminentExposure>(
    entries,
    Metrics.ImminentExposures,
  )[0];
}

export function parseMetrics(entries: MetricsEntries, theme: Theme) {
  return {
    policyCategories: parsePolicyCategories(entries),
    policyGrades: parsePolicyGrades(entries, theme),
    policyRiskRatings: parsePolicyRiskRatings(entries, theme),
    checksResults: parseChecksResults(entries),
    checksRatingCounts: parseChecksRatingCounts(entries),
    vulnerableAssetsSeverity: parseVulnerableAssetsSeverity(entries),
    advisoriesSeverity: parseAdvisoriesSeverity(entries),
    cvesSeverity: parseCVEsSeverity(entries),
    remediationSeverity: parseRemediationSeverity(entries),
    mttrSeverity: parseMTTRSeverity(entries),
    mttrSla: parseMTTRSla(entries),
    assetsGrades: parseAssetsGrades(entries, theme),
    assetRiskRatings: parseAssetRiskRatings(entries),
    passedChecks: parsePassedCheckStats(entries),
    imminentExposures: parseImminentExposures(entries),
  };
}
