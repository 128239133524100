import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid2,
  Link,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { UseSlaConfiguration } from "./useSlaConfiguration";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { SlaRatings } from "./types";

const breadcrumbs = [
  <Link key="home" component={RouterLink} to="/space/overview" display="flex">
    <HomeIcon fontSize="inherit" />
  </Link>,
  <Typography key="security-model">Security Model</Typography>,
  <Typography key="sla-config">SLA Configuration</Typography>,
];

export function SlaConfiguration() {
  const { form, slaFindings, onSubmit, loading } = UseSlaConfiguration();
  const { isValid, isDirty } = form.formState;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid2
        container
        columns={1}
        columnGap={2}
        mt={2}
        mb={4}
        sx={{
          alignItems: {
            xs: "start",
            sm: "center",
          },
        }}
      >
        <Grid2 sx={{ width: "100%" }}>
          <Typography
            component="h1"
            fontWeight="bold"
            sx={{
              fontSize: {
                xs: 22,
                sm: 32,
              },
            }}
          >
            Service Level Agreement (SLA) Configuration
          </Typography>

          <Grid2 container sx={{ pt: 3 }}>
            <Grid2
              size="grow"
              className="sla-config-description"
              sx={{
                pb: 4,
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.background.lighter}`,
              }}
            >
              <Typography sx={{ fontWeight: 700, mb: 4 }}>
                Mondoo dashboards and reports can track your team's performance
                against your service level agreement (SLA) by flagging security
                findings that are nearing or past their SLA date.
              </Typography>
              <Typography>
                Define your SLA for findings based on their risk levels: Once
                Mondoo first discovers a finding, in how many days does your
                team commit to resolving the finding? And in how many days do
                you want a warning that the SLA is nearing?
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      <Box
        component="form"
        sx={{ pb: 3 }}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <List
          sx={(theme) => ({
            ".sla-input": {
              background: theme.palette.code.background,
              borderRadius: 1,
            },
          })}
        >
          <ListItem>
            <Stack direction="row" sx={{ gap: 2, "& > *": { width: 176 } }}>
              <Typography>Finding risk score</Typography>
              <Typography>Days to resolve (SLA)</Typography>
              <Typography>Days before warning</Typography>
            </Stack>
          </ListItem>
          {slaFindings?.map((sla) => {
            const rating = sla.rating as SlaRatings;
            const errors = form.formState.errors[rating];

            return (
              <ListItem key={rating}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ gap: 2, "& > *": { width: 176 } }}
                >
                  <ImpactUpdated
                    impact={{
                      rating: sla.rating,
                    }}
                    options={{ fontSize: 12, fontWeight: 700 }}
                  />
                  <TextField
                    {...form.register(`${rating}.daysToResolve` as const, {
                      required: true,
                      valueAsNumber: true,
                    })}
                    type="number"
                    slotProps={{ htmlInput: { min: 0 } }}
                    className="sla-input"
                    variant="outlined"
                    sx={{ ...helperTextStyles }}
                    helperText={
                      errors?.daysToResolve && (
                        <ValidationMessage error={errors.daysBeforeWarning} />
                      )
                    }
                  />
                  <TextField
                    {...form.register(`${rating}.daysBeforeWarning` as const, {
                      required: true,
                      valueAsNumber: true,
                    })}
                    type="number"
                    slotProps={{ htmlInput: { min: 0 } }}
                    className="sla-input"
                    variant="outlined"
                    sx={{ ...helperTextStyles }}
                    helperText={
                      errors?.daysBeforeWarning && (
                        <ValidationMessage error={errors.daysBeforeWarning} />
                      )
                    }
                  />
                </Stack>
              </ListItem>
            );
          })}
        </List>
        <Box
          className="sla-config-form-actions"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            pt: 4,
            pb: 0,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isDirty || !isValid || loading}
            data-testid="apply-sla-changes"
          >
            {loading && (
              <CircularProgress
                color="secondary"
                size={20}
                sx={{ position: "absolute" }}
              />
            )}
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export const helperTextStyles = {
  ".MuiFormHelperText-root": {
    position: "absolute",
    top: "100%",
    fontSize: 8,
    m: 0,
    pt: 0,
    px: 1,
    backgroundColor: "background.paper",
  },
  ".MuiFormHelperText-root span": {
    fontSize: 12,
  },
};
