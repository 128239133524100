import { Box, Typography } from "@mui/material";
import {
  FindingsOrder,
  FindingsOrderField,
  FindingType,
  ScoreRating,
  useGetCvEsQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { setDocumentTitle } from "~/utils/commonUtils";
import { OrderDirection } from "~/components/ui-library";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { ImminentExposuresBreadcrumbs } from "./ImminentExposuresBreadcrumbs";
import { getError } from "~/lib/handle-error";
import DashboardNoFindingSvg from "~/components/icons/mondoo/dashboard/DashboardNoFindingSvg";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";
import { FindingsTable } from "~/pages/inventory/components/Findings/FindingsTable/FindingsTable";
import { RISK_FACTORS } from "~/pages/space/software/Software/utils";

// TODO: All comments in this component are for Imminent Exposures second milestone

// enum FilterOption {
//   FINDINGS = "findings",
//   FINDINGS_ON_ASSETS = "findings_on_assets",
// }

interface ImminentExposuresPageProps {
  space: Space;
  scope: SpaceOrWorkspaceScope;
}

const ImminentExposuresEmptyState = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: { xs: 3, sm: 5, md: 10, lg: 15 },
      gap: { xs: 2, sm: 3 },
      textAlign: "center",
      background: (theme) => theme.palette.background.paper,
      borderRadius: 1,
    }}
  >
    <Box
      sx={{
        "& svg": {
          width: { xs: 40, sm: 48 },
          height: { xs: 40, sm: 48 },
          color: "text.secondary",
        },
      }}
    >
      <DashboardNoFindingSvg />
    </Box>

    <Box sx={{ maxWidth: { sm: "80%", md: "70%", lg: "60%" } }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "700",
          fontSize: { xs: "18px", sm: "20px", md: "24px" },
          mb: { xs: 1, sm: 1.5 },
          lineHeight: 1.2,
        }}
      >
        No imminent exposures found!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "medium",
          color: "text.secondary",
          fontSize: { xs: "14px", sm: "16px" },
          lineHeight: { xs: 1.4, sm: 1.5 },
        }}
      >
        Your inventory has been scanned for remotely exploitable vulnerabilities
        on assets exposed to the internet, and we found none.
      </Typography>
    </Box>
  </Box>
);

export function ImminentExposuresPage({
  space,
  scope,
}: ImminentExposuresPageProps) {
  setDocumentTitle(["Imminent Exposures", "Dashboard"]);
  // const [filterType, setFilterType] = useState<FilterOption>(
  //   FilterOption.FINDINGS,
  // );

  // const handleFilterChange = (event: SelectChangeEvent<FilterOption>) => {
  // setFilterType(event.target.value as FilterOption);
  // };

  const { handleSortClick, orderBy } = useSort<FindingsOrder>({
    defaultSort: {
      field: FindingsOrderField.RiskScore,
      direction: OrderDirection.Desc,
    },
    validFields: [FindingsOrderField.RiskScore],
  });

  const { data, error, loading, fetchMore } = useGetCvEsQuery({
    variables: {
      scopeMrn: space.mrn,
      first: 10,
      orderBy,
      filter: {
        rating: [ScoreRating.Critical],
        types: [FindingType.Cve],
        riskFactors: {
          mrns: {
            and: [
              RISK_FACTORS.INTERNET_EXPOSED_RISK_FACTOR,
              RISK_FACTORS.REMOTE_EXPLOITABLE_RISK_FACTOR,
            ],
          },
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <LoadingPage what="Imminent Exposures" />;
  }

  if (!loading && error) {
    return (
      <LoadingFailedPage what="Imminent Exposures" message={getError(error)} />
    );
  }

  const cveFindingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data?.findings
      : undefined;

  const cveFindings: CVEFindingsNode[] = (
    cveFindingsUnion?.edges || []
  ).flatMap((e) => (e?.node?.__typename === "CveFinding" ? e.node : []));

  const pageInfo = cveFindingsUnion?.pageInfo;
  const edges = cveFindingsUnion?.edges || [];
  const totalCount = cveFindingsUnion?.totalCount || 0;
  const isEmpty = edges.length === 0;

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 4 },
        py: { xs: 2, sm: 3 },
        maxWidth: "100%",
      }}
    >
      <ImminentExposuresBreadcrumbs scope={scope} />

      <Box mb={{ xs: 2, sm: 2.5, md: 3 }}>
        <Typography
          variant="h4"
          fontWeight={700}
          sx={{
            fontSize: { xs: "24px", sm: "28px", md: "32px" },
            mb: { xs: 1, sm: 1.5 },
          }}
        >
          IMMINENT EXPOSURES
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontSize: { xs: "14px", sm: "16px" },
            lineHeight: { xs: 1.4, sm: 1.5 },
          }}
        >
          These findings jeopardize your business. They reveal remotely
          exploitable vulnerabilities on assets exposed to the internet. To
          avert the most likely breaches to your infrastructure, fix them today.
        </Typography>
      </Box>

      {!loading && isEmpty && <ImminentExposuresEmptyState />}

      {!loading && !isEmpty && (
        <>
          <Box
            sx={{
              overflow: "auto",
              borderRadius: { xs: 1, sm: 1 },
              boxShadow: { xs: 1, sm: 1, md: 0 },
            }}
          >
            <FindingsTable
              onSort={handleSortClick}
              orderBy={orderBy}
              findings={cveFindings}
              fetchMore={fetchMore}
              totalCount={totalCount}
              pageInfo={pageInfo}
              availablePermissions={space.iamActions}
              context="imminent_exposure"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
