import { useEffect, useState } from "react";
import { Theme, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export function useScreenSize() {
  const [mediaSize, setMediaSize] = useState("xs");

  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs"),
  );
  const matchesSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("sm"),
  );
  const matchesMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("md"),
  );
  const matchesLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("lg"),
  );

  useEffect(() => {
    if (matchesXs) {
      return setMediaSize("xs");
    }

    if (matchesSm) {
      return setMediaSize("sm");
    }

    if (matchesMd) {
      return setMediaSize("md");
    }

    if (matchesLg) {
      return setMediaSize("lg");
    }
  }, [matchesXs, matchesSm, matchesMd, matchesLg]);

  const isXs = mediaSize === "xs";
  const isSm = mediaSize === "sm";
  const isMd = mediaSize === "md";
  const isLg = mediaSize === "lg";
  const isSmallerThanMd = isXs || isSm;
  const isSmallerThanLg = isXs || isSm || isMd;
  const isLargerThanXs = isSm || isMd || isLg;
  const isLargerThanSm = isMd || isLg;
  const isLargerThanMd = isLg;

  const mediaSizeTextBlock = (
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      Current screen size: {mediaSize}
    </Typography>
  );

  return {
    mediaSize,
    mediaSizeTextBlock,
    size: {
      isXs,
      isSm,
      isMd,
      isLg,
      isSmallerThanMd,
      isSmallerThanLg,
      isLargerThanXs,
      isLargerThanSm,
      isLargerThanMd,
    },
  };
}
