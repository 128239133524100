import {
  alpha,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  AttachIcon,
  CloseIcon,
  EditOffIcon,
  SnoozeIcon,
} from "~/components/icons";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { TriageBarProps } from "~/components/TriageBar/TriageBar";

interface Props {
  triageBarTitle: string;
  open: boolean;
  onClose: () => void;
  availableRemediations?: TriageBarProps["availableRemediations"];
}

export function TriageModal({
  triageBarTitle,
  open,
  onClose,
  availableRemediations,
}: Props) {
  const isCreateTicketActive = availableRemediations?.ticket.active;
  const isSnoozeExceptionActive = availableRemediations?.snooze.active;
  const isSetExceptionActive = availableRemediations?.exception.active;
  const exception = availableRemediations?.exception;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="triage-modal-title"
      aria-describedby="triage-modal-description"
      sx={(theme) => ({
        ".triage-button": {
          maxWidth: { xs: "100%", sm: theme.spacing(48) },
          minWidth: { xs: "100%", sm: theme.spacing(32) },
          height: theme.spacing(6),
          border: `2px solid ${theme.palette.primary.main}`,
          fontSize: 13,
          fontWeight: "700",
          letterSpacing: ".46px",
        },
        ".triage-button.transparent": {
          background: "transparent",
        },
        ".triage-button .MuiSvgIcon-fontSizeMedium": {
          fontSize: 16,
        },
        ".triage-button .MuiButton-startIcon": {
          mr: 0.5,
        },
      })}
    >
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 5,
          p: 5,
        }}
      >
        <Typography
          id="triage-modal-title"
          variant="h5"
          sx={{
            fontWeight: "700",
            paddingTop: "5px",
          }}
        >
          Take action for {triageBarTitle}
        </Typography>

        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: "text.secondary" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 5, pb: 5 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: 400, margin: "0 auto" }}
        >
          <Typography sx={{ mb: 3, fontWeight: 700 }}>
            Choose how to address this finding
          </Typography>
          <Stack direction="column" gap={3} sx={{ width: "80%" }}>
            {isCreateTicketActive && (
              <Button
                variant="contained"
                className="triage-button"
                sx={{
                  background: (theme) => alpha(theme.palette.primary.main, 0.4),
                }}
                startIcon={<AttachIcon sx={{ fontSize: 46 }} />}
                onClick={availableRemediations?.ticket.onClick}
                disabled={availableRemediations?.ticket.disabled}
                // reference Check.tsx
                // onClick={() => {
                // setSelectedAssets([
                //   {
                //     mrn: findingMrn,
                //     scopeMrn: scope.mrn,
                //     groupId: "ALL_ASSETS",
                //   },
                // ]);
                // handleCreateCaseModalOpen();
                // }}
              >
                Create Ticket
              </Button>
            )}
            {isSetExceptionActive && (
              <Button
                variant="contained"
                className="triage-button transparent"
                startIcon={
                  exception?.hasException ? (
                    <EditOffIcon sx={{ fontSize: 16 }} />
                  ) : (
                    <DoNotDisturbOnOutlinedIcon sx={{ fontSize: 16 }} />
                  )
                }
                onClick={exception?.onClick}
                disabled={exception?.disabled}
              >
                {exception?.hasException
                  ? "Remove Exception and Enable"
                  : "Set Exception"}
              </Button>
            )}

            {isSnoozeExceptionActive && (
              <Button
                variant="contained"
                className="triage-button transparent"
                startIcon={<SnoozeIcon sx={{ fontSize: 16 }} />}
              >
                Snooze
              </Button>
            )}
          </Stack>
          {/*<Divider
                        sx={{
                          width: "100%",
                          maxWidth: 260,
                          my: 3,
                          ".MuiDivider-wrapper": {
                            padding: 0,
                          },
                          "&:before, &:after": {
                            borderTop: (theme) =>
                              `2px solid ${alpha(theme.palette.text.disabled, 0.5)}`,
                          },
                        }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: 48,
                                height: 48,
                                border: "2px solid",
                                borderColor: (theme) => alpha(theme.palette.text.disabled, 0.5),
                                borderRadius: "50%",
                              }}
                            >
                              <Typography fontWeight={500}>OR</Typography>
                            </Box>
                          </Divider>
                          <Typography sx={{ mb: 2, fontWeight: 700 }}>
                            START A CONVERSATION
                          </Typography>
                          <Stack direction="row" gap={3}>
                            <IconButton>
                              <MsTeamsIcon sx={{ fontSize: 48 }} />
                            </IconButton>
                            <IconButton>
                              <SlackColorIcon sx={{ fontSize: 48 }} />
                            </IconButton>
                          </Stack>*/}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
