import { MouseEvent, useState } from "react";
import { Button, TableCell } from "@mui/material";
import { ArrowForwardIcon } from "~/components/icons";
import { TriageModal } from "~/components/TriageBar/TriageModal";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { useCases } from "~/components/cases/hooks";
import {
  GetAggregateScoresDocument,
  GetCasesCountDocument,
  useGetAggregateScoresQuery,
  useGetTicketingIntegrationsQuery,
} from "~/operations";
import { ScopeType, useScope } from "~/hooks/useScope";
import { isFeatureEnabled } from "~/login/features";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { TriageBarProps } from "~/components/TriageBar/TriageBar";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";

type TriageCellProps = {
  cveFinding: CVEFindingsNode;
};

export const FindingTableTriageCell = ({ cveFinding }: TriageCellProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { spaceScope } = useScope();

  const { setSelectedEntities, selectedEntities } = useExceptionsSelection();
  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseModalOpen,
    isCreateCaseModalOpen,
    loading: createCaseLoading,
    handleCreateCaseModalClose,
    handleCreateCase,
    hasCreateCasePermissions,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions: spaceScope?.iamActions || [],
  });

  const {
    handleRemoveException,
    handleSetExceptionModalOpen,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedEntities([]);
    },
    onRemoveException: () => {
      setSelectedEntities([]);
    },
    scopeMrns: [spaceScope?.mrn || ""],
    cveMrns: [cveFinding.mrn],
    refetchQueries: [GetAggregateScoresDocument],
  });

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: spaceScope?.mrn || "",
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasCreateCasePermissions,
  });

  const hasTicketingIntegration =
    (ticketingIntegrationsData?.ticketingIntegrations?.integrations?.length ||
      0) > 0;

  const { data: aggScoreData } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: spaceScope?.mrn || "",
      filter: {
        findingMrn: cveFinding.mrn,
      },
    },
    skip: !cveFinding.mrn,
  });

  const aggScore =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  const availableRemediations: TriageBarProps["availableRemediations"] = {
    ticket: {
      active: true,
      onClick: hasTicketingIntegration
        ? () => {
            setSelectedEntities([
              {
                mrn: cveFinding.mrn || "",
                scopeMrn: spaceScope?.mrn || "",
                groupId: "ALL_ASSETS",
              },
            ]);
            handleCreateCaseModalOpen();
          }
        : handleCreateCaseIntegrationModalOpen,
    },
    exception: {
      active:
        isFeatureEnabled("Space level exceptions") &&
        spaceScope?.type === ScopeType.Space,
      hasException: !!aggScore?.exception,
      onClick: !!aggScore?.exception
        ? handleRemoveException
        : () => {
            setSelectedEntities([
              {
                mrn: cveFinding?.mrn || "",
                exception: aggScore?.exception,
                groupId: "SCOPE_CVE",
              },
            ]);
            handleSetExceptionModalOpen();
          },
      disabled: exceptionsLoading,
    },
    snooze: { active: false },
  };

  const handleOpenModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setModalOpen(true);
  };

  const handleCloseModal = (event?: MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setModalOpen(false);
  };

  return (
    <TableCell
      align="right"
      onClick={(e: MouseEvent<HTMLTableCellElement>) => e.stopPropagation()}
      sx={{ position: "relative", zIndex: 1 }}
    >
      <Button
        variant="contained"
        size="small"
        endIcon={<ArrowForwardIcon />}
        onClick={handleOpenModal}
        sx={{
          bgcolor: "secondary.main",
          color: "common.white",
          fontWeight: 700,
          "&:hover": {
            bgcolor: "secondary.dark",
          },
          px: 2,
          py: 0.5,
          fontSize: 12,
          textTransform: "uppercase",
          borderRadius: 1,
        }}
      >
        Triage
      </Button>

      <TriageModal
        triageBarTitle={cveFinding.id}
        open={modalOpen}
        onClose={handleCloseModal}
        availableRemediations={availableRemediations}
      />

      {spaceScope && (
        <CreateCaseModal
          open={isCreateCaseModalOpen}
          target="asset"
          loading={createCaseLoading}
          onClose={() => {
            handleCreateCaseModalClose();
            setSelectedEntities([]);
          }}
          onSave={handleCreateCase}
          selectedAssets={selectedEntities}
          space={spaceScope}
          scope={spaceScope}
          hasCreateCasePermissions={hasCreateCasePermissions}
        />
      )}
    </TableCell>
  );
};
