import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Breadcrumbs, Box, Grid2 } from "@mui/material";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import { TestIamActionsQuery, useGeneratePolicyMutation } from "~/operations";
import { AddButton } from "~/components/add-button";
import { Flex } from "~/components/Flex";
import { setDocumentTitle } from "~/utils/commonUtils";
import { PromptForm } from "../PromptForm";
import { SkeletonLines } from "./SkeletonLines";
import { GeneratedCode } from "./GeneratedCode";

export type GenerateProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Generate({ space }: GenerateProps) {
  const [generatedCode, setGeneratedCode] = useState<string>();
  const [generateCodeFromPrompt, { data, error, loading }] =
    useGeneratePolicyMutation();

  useEffect(() => {
    if (data) {
      if (data.generatePolicy.content) {
        setGeneratedCode(data.generatePolicy.content);
      }
    }
  }, [data, error]);

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/findings/policies"
      component={RouterLink}
      to={`/space/findings/policies?spaceId=${space.id}`}
    >
      Policies
    </Link>,
    <Typography key="/space/overview/vulnerabilities/policies/generate">
      Generate
    </Typography>,
  ];

  setDocumentTitle(["Generate", "Policies", "Security"]);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="add-policies-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={700}
          textTransform="uppercase"
          sx={{ mb: 3 }}
        >
          Generate Policies
        </Typography>
        <Flex id="add-policies-actions" alignItems="center" gap={4}>
          <AddButton
            id="cancel-add-policy-button"
            href={`/space/findings/policies?spaceId=${space.id}`}
            aria-label="Cancel Add Policy"
            close
          />
        </Flex>
      </Box>

      <Grid2 container>
        <Grid2 size={{ xs: 12 }}>
          <PromptForm onGenerate={generateCodeFromPrompt} />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          {loading && <SkeletonLines />}
          {!loading && (generatedCode || error) && (
            <GeneratedCode code={generatedCode} error={error} />
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
}
