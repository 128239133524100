import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { input } from "@testing-library/user-event/dist/cjs/event/input.js";
import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { RefreshIcon } from "~/components/icons";
import { useScope } from "~/hooks/useScope";
import { getError } from "~/lib/handle-error";
import { Validations } from "~/lib/validations";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { FieldLabel } from "~/pages/space/Workspaces/components";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";

type PromptFormInput = {
  prompt: string;
  models: string[];
};

const providers = [
  { label: "OS", value: "os" },
  { label: "AWS", value: "aws" },
  { label: "Ansible", value: "ansible" },
  { label: "Azure", value: "azure" },
  { label: "GitHub", value: "github" },
  { label: "GitLab", value: "gitlab" },
  { label: "Google Cloud", value: "gcp" },
  { label: "OCI", value: "oci" },
  { label: "Terraform", value: "terraform" },
  { label: "VMware", value: "vsphere" },
];

type PromptFormProps = {
  onGenerate: any;
};

export function PromptForm({ onGenerate }: PromptFormProps) {
  const { activeScope } = useScope();

  const {
    handleSubmit,
    register,
    formState: { errors },
    getFieldState,
  } = useForm<PromptFormInput>({
    defaultValues: {
      prompt: "",
      models: [],
    },
  });

  const onSubmit: SubmitHandler<PromptFormInput> = async (data) => {
    try {
      onGenerate({
        variables: {
          input: {
            scopeMrn: activeScope?.mrn,
            prompts: [data.prompt],
            providers: data.models,
          },
        },
      });
    } catch (e) {
      const error = getError(e);
      console.error("Error generating policy:", error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <FieldLabel
        id="prompt-label"
        label="Policy prompt"
        caption={`Tell us below what you want the policy to do.`}
      />
      <Grid2 container gap={1}>
        <Grid2 size="grow">
          <TextField
            id="prompt-input"
            fullWidth
            placeholder="Generate a YAML policy about security best-practices for SSH"
            variant="outlined"
            slotProps={{
              input: {
                sx: { backgroundColor: "code.background" },
              },
            }}
            error={getFieldState("prompt").isTouched && Boolean(errors.prompt)}
            helperText={
              getFieldState("prompt").isTouched &&
              Boolean(errors.prompt) && (
                <ValidationMessage error={errors.prompt} />
              )
            }
            disabled={false}
            {...register("prompt", {
              required: true,
            })}
          />
        </Grid2>
        <Grid2 size="auto">
          <FormControl fullWidth>
            <InputLabel id="model-label">Providers</InputLabel>
            <Select
              multiple
              fullWidth
              label="Providers"
              defaultValue={["os"]}
              displayEmpty
              sx={{
                "&.MuiInputBase-root": {
                  bgcolor: "code.background",
                },
                width: 220,
              }}
              {...register("models", {
                required: true,
              })}
            >
              {providers.map((v) => {
                return (
                  <MenuItem value={v.value} key={v.value}>
                    {v.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2>
          <IconButton type="submit" sx={{ mt: 1 }}>
            <RefreshIcon />
          </IconButton>
        </Grid2>
      </Grid2>
    </Box>
  );
}
