import { alpha, Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

export const DisplayedError = ({ error }: { error: string }) => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      sx={{
        background: (theme) =>
          `linear-gradient(170deg, ${alpha(theme.palette.error.main, 0.2)} 0%, transparent 65%)`,
        mt: 2,
        padding: 2,
        borderRadius: 1,
        minHeight: 300,
        color: "error.main",
      }}
    >
      <Typography variant="body1">{error}</Typography>
    </Box>
  );
};
