import { Fragment } from "react/jsx-runtime";
import { InventoryPageHeader } from "~/routes";
import {
  AssetInsights,
  ExploreWorkspace,
  InfoBar,
  QueryResults,
  References,
  RelatedFindings,
  RelatedPolicies,
  Remediation,
  SummaryData,
  VulnerabilityInsights,
} from "./components";

export function FindingOnAsset() {
  return (
    <Fragment>
      <InfoBar />
      <InventoryPageHeader disableYPadding>
        <SummaryData />
        <AssetInsights />
        <VulnerabilityInsights />
        <Remediation />
        <QueryResults />
        <RelatedPolicies />
        <RelatedFindings />
        <ExploreWorkspace />
        <References />
      </InventoryPageHeader>
    </Fragment>
  );
}
