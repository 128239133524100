export function trimSoftwareMrnVersion(versionedSoftware: string) {
  const version = versionedSoftware.split("/").pop() || "";
  return versionedSoftware.replace(version, "").slice(0, -1);
}

export const RISK_FACTORS = {
  REMOTE_EXPLOITABLE_RISK_FACTOR:
    "//policy.api.mondoo.app/risks/vuln-remote-exploitable",
  INTERNET_EXPOSED_RISK_FACTOR:
    "//policy.api.mondoo.app/risks/internet-exposed",
};
