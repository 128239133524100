import { Checkbox } from "@mui/material";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { IamActions } from "~/lib/iam";
import {
  ExceptionMutationAction,
  ExceptionType,
  GetAdvisoriesDocument,
  GetChecksDocument,
  GetCvEsDocument,
  LoadAssetDocument,
  ScoreState,
  TestIamActionsQuery,
} from "~/operations";
import { ExceptionTarget } from "~/components/exceptions/types";
import {
  FindingContext,
  findingsTableRowHrefBuilder,
  findingTableRowExceptionHrefBuilder,
  getFindingTableHeaders,
} from "~/pages/inventory/components/Findings/FindingsTable/findingTableUtils";
import { useScope } from "~/hooks/useScope";

type UseAssetVulnerabilitiesTableProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  context: FindingContext;
  entityMrn: string;
  items: {
    mrn: string;
    state: ScoreState;
    exception?: {
      id: string;
      action: ExceptionMutationAction;
    } | null;
  }[];
};

export function useFindingsTable({
  availablePermissions,
  context,
  entityMrn,
  items,
}: UseAssetVulnerabilitiesTableProps) {
  const { activeScope: scope } = useScope();
  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities,
    setSelectedEntities,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedEntities([]);
    },
    onRemoveException: () => {
      setSelectedEntities([]);
    },
    scopeMrns: [entityMrn],
    advisoryMrns:
      context === "advisory"
        ? mapSelectedEntitiesToString(selectedEntities)
        : [],
    cveMrns:
      context === "cve" || context === "imminent_exposure"
        ? mapSelectedEntitiesToString(selectedEntities)
        : [],
    queryMrns:
      context === "check" ? mapSelectedEntitiesToString(selectedEntities) : [],
    applyToCves: context === "advisory",
    refetchQueries: [
      context === "cve" || context === "imminent_exposure"
        ? GetCvEsDocument
        : GetAdvisoriesDocument,
      ...(context === "check" ? [GetChecksDocument] : []),
      LoadAssetDocument,
    ],
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: entityMrn,
    types:
      context === "advisory" ? [ExceptionType.Advisory] : [ExceptionType.Cve],
  });

  const targetPaginatedGroup = items.map((item) => {
    return {
      mrn: item.mrn as string,
      exception: item.exception,
      groupId: item.exception?.id,
    };
  });

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const AssetVulnerabilitiesTableHeaders = {
    SELECT: {
      id: "SELECT",
      label: (
        <Checkbox
          checked={isGroupChecked(targetPaginatedGroup)}
          indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onGroupCheckChange(event, targetPaginatedGroup)
          }
        />
      ),
      sortable: false,
    },
    RISK_VALUE: {
      id: "RISK_VALUE",
      label: "Risk",
      options: { width: "10%" },
    },
    ID: {
      id: "ID",
      label: "Advisory",
      options: { width: "50%" },
      sortable: false,
    },
    VULNERABILITY_ID: {
      id: "VULNERABILITY_ID",
      label: "Vulnerability",
      options: { width: "50%" },
      sortable: false,
    },
    CHECK_ID: {
      id: "CHECK_ID",
      label: "Check",
      options: { width: "50%" },
      sortable: false,
    },
    RISKFACTORS: {
      id: "RISKFACTORS",
      label: "Risk factors",
      sortable: false,
    },
    PUBLISHED_DATE: {
      id: "PUBLISHED_DATE",
      label: "Published",
      // Backend does not support sorting by this column yet
      sortable: false,
    },
    TRIAGE_BUTTON: {
      id: "TRIAGE_BUTTON",
      label: "",
      sortable: false,
    },
  };

  const tableHeaders = getFindingTableHeaders(
    context,
    hasApplyExceptionPermission,
    AssetVulnerabilitiesTableHeaders,
  );

  function getFindingExceptionTarget(): ExceptionTarget {
    switch (context) {
      case "advisory":
        return "advisory";
      case "cve":
      case "imminent_exposure":
        return "cve";
      case "check":
        return "check";
      default:
        return "finding";
    }
  }

  const getFindingsTableRowHref = (assetId: string | undefined) => {
    return findingsTableRowHrefBuilder({
      context,
      assetId,
      scopeParams: scope?.params,
    });
  };

  const getFindingTableRowExceptionHref = (
    exceptionId: string,
    assetId: string | undefined,
  ) => {
    return findingTableRowExceptionHrefBuilder({
      exceptionId,
      assetId,
      scopeId: scope?.id,
    });
  };

  return {
    hasApplyExceptionPermission,
    findingExceptionTarget: getFindingExceptionTarget(),
    tableHeaders,
    exceptionGroups,
    getFindingsTableRowHref,
    getFindingTableRowExceptionHref,
    targetPaginatedGroup,
    exception: {
      isRemovingException,
      isSettingException,
      handleSetExceptionModalOpen,
      handleSetExceptionModalClose,
      handleRemoveExceptionModalOpen,
      handleRemoveExceptionModalClose,
      handleRemoveException,
      handleSetException,
      loading: exceptionsLoading,
    },
    exceptionsSelection: {
      isGroupChecked,
      isGroupIndeterminate,
      onGroupCheckChange,
      selectedEntities,
      setSelectedEntities,
      handleNodeClick,
      handleNodeChange,
      handleCancelClick,
    },
  };
}
