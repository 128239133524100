import { Route, Routes, Navigate } from "react-router-dom";
import { SlaConfiguration } from "~/pages/security-model/sla-configuration";
import { RiskFactorsConfiguration } from "~/pages/security-model/RiskFactorsConfiguration";
import { PageLayout } from "~/routes";
import { Space } from "~/lib/types";

type Props = {
  space: Space;
};

export const SecurityModelRoutes = ({ space }: Props) => (
  <Routes>
    <Route element={<PageLayout />}>
      <Route index element={<Navigate to="risk-factors" replace />} />
      <Route
        path="risk-factors"
        element={<RiskFactorsConfiguration space={space} />}
      />
      <Route path="slas" element={<SlaConfiguration />} />
    </Route>
  </Routes>
);
