import { Tooltip, useTheme } from "@mui/material";
import { useRef, useEffect, useState, CSSProperties } from "react";

interface CenterEllipseTextProps {
  id: string;
  title: string;
  TextStyle?: CSSProperties;
}

export default function CenterEllipseText({
  id,
  title,
  TextStyle,
}: CenterEllipseTextProps) {
  let theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [displayTitle, setDisplayTitle] = useState(title);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    // Initial measurement
    updateWidth();

    // Set up resize observer
    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Clean up
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!containerWidth || containerWidth <= 0) return;

    // Check if title needs truncation
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return;

    // Get computed style to match the actual font
    const computedStyle = containerRef.current
      ? window.getComputedStyle(containerRef.current)
      : null;

    if (computedStyle) {
      context.font = `${computedStyle.fontWeight} ${computedStyle.fontSize} ${computedStyle.fontFamily}`;
    }

    const textWidth = context.measureText(title).width;

    if (textWidth <= containerWidth) {
      setDisplayTitle(title);
    } else {
      // Estimate characters that will fit on each side
      const avgCharWidth = textWidth / title.length;
      const totalCharsToShow = Math.floor(containerWidth / avgCharWidth) - 5; // -5 for ellipsis + spaces;

      if (containerWidth <= 0 || totalCharsToShow <= 3) {
        // Container is too small to show anything meaningful
        setDisplayTitle("...");
      } else {
        // Split characters evenly between start and end
        const charsPerSide = Math.floor(totalCharsToShow / 2);
        const start = title.substring(0, charsPerSide);
        const end = title.substring(title.length - charsPerSide);
        setDisplayTitle(`${start}...${end}`);
      }
    }
  }, [title, containerWidth]);

  return (
    <Tooltip title={title} placement="top" arrow>
      <div
        id={id}
        ref={containerRef}
        style={{
          display: "block",
          width: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontFamily: theme.typography.fontFamily,
          ...TextStyle,
        }}
      >
        {displayTitle}
      </div>
    </Tooltip>
  );
}
