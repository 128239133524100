import { createSvgIcon } from "@mui/material";

const SecurityModelSvg = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0005 2.00037C11.8095 2.00037 11.6195 2.03837 11.4405 2.11437L3.87547 5.15537C3.34447 5.38037 3.00047 5.90137 3.00047 6.47737V10.9904C3.00047 15.9774 6.84847 20.5834 11.6055 21.9454C11.7345 21.9824 11.8675 22.0004 12.0005 22.0004C12.1335 22.0004 12.2665 21.9824 12.3955 21.9454C17.1525 20.5834 21.0005 15.9774 21.0005 10.9904V6.47737C21.0005 5.90137 20.6565 5.38037 20.1255 5.15537L12.5605 2.11437C12.3815 2.03837 12.1915 2.00037 12.0005 2.00037ZM12.0005 3.43537L19.5655 6.47737V10.9904C19.5655 15.3114 16.1005 19.3924 12.0005 20.5654C7.90047 19.3924 4.43547 15.3114 4.43547 10.9904V6.47737L12.0005 3.43537Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.93981 7.7983C11.0202 7.37134 12.2942 7.59221 13.1684 8.46648C14.0418 9.34445 14.2636 10.6198 13.8338 11.7011L20.8617 18.729C21.0466 18.9138 21.0466 19.2049 20.8617 19.3805L19.7989 20.4433C19.6187 20.6235 19.3276 20.6235 19.1474 20.4433L13.7654 15.0614L12.1056 13.4016C11.0336 13.8036 9.78132 13.5772 8.92184 12.7177C8.04941 11.849 7.82761 10.5764 8.25135 9.49879L10.4749 11.7224L12.1694 10.0279L9.93981 7.7983Z"
      fill="currentColor"
    />
  </svg>,
  "SecurityModel",
);

export default SecurityModelSvg;
