import { enqueueSnackbar } from "notistack";
import { SubmitHandler, UseControllerProps, useForm } from "react-hook-form";
import { useScope } from "~/hooks/useScope";
import { getError } from "~/lib/handle-error";
import {
  GetMetricsDocument,
  GetSecurityModelDocument,
  ScoreRating,
  useGetSecurityModelQuery,
  useUpdateSecurityModelMutation,
} from "~/operations";
import { Metrics } from "~/utils/arrow";
import { SlaFormInput, SlaRatings } from "./types";
import { useEffect } from "react";

export function UseSlaConfiguration() {
  const { activeScope } = useScope();

  const { data } = useGetSecurityModelQuery({
    variables: {
      scopeMrn: activeScope?.mrn || "",
    },
    skip: !activeScope,
  });

  const [updateSecurityModel, { loading }] = useUpdateSecurityModelMutation({
    refetchQueries: [
      {
        query: GetSecurityModelDocument,
        variables: {
          scopeMrn: activeScope?.mrn || "",
        },
      },
      // Dashboard metrics don't seem to be reloading
      {
        query: GetMetricsDocument,
        variables: {
          entityMrn: activeScope?.mrn || "",
          metricMrns: [Metrics.MTTRSla],
        },
      },
    ],
  });

  const form = useForm<SlaFormInput>({
    mode: "onBlur",
    defaultValues,
  });

  const formRules: UseControllerProps<SlaFormInput>["rules"] = {
    min: 0,
    required: true,
  };

  useEffect(() => {
    const slaFindings = data?.securityModel?.slas?.findings;
    if (!slaFindings) return;

    const returnedValues: SlaFormInput =
      slaFindings
        ?.filter((sla) => sla.rating !== ScoreRating.None)
        .reduce((acc, sla) => {
          acc[sla.rating as keyof SlaFormInput] = {
            daysToResolve: sla.daysToResolve,
            daysBeforeWarning: sla.daysBeforeWarning,
          };
          return acc;
        }, defaultValues) || defaultValues;

    form.reset(returnedValues);
  }, [data?.securityModel?.slas?.findings]);

  const onSubmit: SubmitHandler<SlaFormInput> = async (data) => {
    if (!activeScope) return;
    if (!form.formState.isDirty || !form.formState.isValid) return;

    try {
      await updateSecurityModel({
        variables: {
          input: {
            scopeMrn: activeScope.mrn,
            slas: {
              findings: Object.entries(data).map(([key, value]) => ({
                rating: key as SlaRatings,
                daysToResolve: value.daysToResolve,
                daysBeforeWarning: value.daysBeforeWarning,
              })),
            },
          },
        },
      });
      enqueueSnackbar("SLA configuration updated successfully", {
        variant: "success",
      });
    } catch (error) {
      const message = getError(error);
      enqueueSnackbar(`Failed to update SLA configuration: ${message}`, {
        variant: "error",
      });
    }
  };

  return {
    form,
    formRules,
    updateSecurityModel,
    onSubmit,
    slaFindings: data?.securityModel?.slas?.findings,
    loading,
  };
}

export const defaultValues: SlaFormInput = {
  [ScoreRating.Low]: {
    daysToResolve: 0,
    daysBeforeWarning: 0,
  },
  [ScoreRating.Medium]: {
    daysToResolve: 0,
    daysBeforeWarning: 0,
  },
  [ScoreRating.High]: {
    daysToResolve: 0,
    daysBeforeWarning: 0,
  },
  [ScoreRating.Critical]: {
    daysToResolve: 0,
    daysBeforeWarning: 0,
  },
} satisfies SlaFormInput;
