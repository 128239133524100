import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  CheckScoreOrder,
  CheckScoreOrderField,
  FindingsFilter,
  FindingsOrder,
  FindingsOrderField,
  OrderDirection,
  ScoreStateFilter,
  useGetAffectedAssetsQuery,
  useGetCheckScoresQuery,
} from "~/operations";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { usePolicyOutletContext } from "~/components/policy-gql/policy-gql";
import { useSearch } from "~/components/search/useSearch";
import { ASSET_TABLE_INITIAL_PAGE_RANGE } from "~/components/pagination";

type UseAffectedAssetsParams = {
  scope: SpaceOrWorkspaceScope;
};

// THIS IS A HOLDOVER UNTIL FINDINGS (OR SOMEOTHER IMPLEMENTATIONO) CAN SUPPORT ASSETS AT THE POLICY LEVEL
export function usePolicyAssets({ scope }: UseAffectedAssetsParams) {
  const { searchFilters } = useSearch();
  const { policy } = usePolicyOutletContext();
  const { handleSortClick, orderBy } = useSort<CheckScoreOrder>({
    defaultSort: {
      field: CheckScoreOrderField.RiskValue,
      direction: OrderDirection.Desc,
    },
    validFields: [CheckScoreOrderField.Score, CheckScoreOrderField.AssetName],
  });

  const {
    data: checkData,
    error,
    loading,
    fetchMore,
  } = useGetCheckScoresQuery({
    variables: {
      entityMrn: scope.mrn,
      first: ASSET_TABLE_INITIAL_PAGE_RANGE.to,
      after: null,
      orderBy,
      filter: {
        queryTerms: searchFilters,
        policyMrn: policy.mrn,
        state: ScoreStateFilter.Open,
      },
    },
  });

  const checkScores =
    checkData?.checkScores?.__typename === "CheckScoresConnection"
      ? checkData.checkScores
      : undefined;

  const { edges, pageInfo, filteredTotalCount } = checkScores || {};

  const assets = edges?.flatMap(({ node }) => {
    if (!node?.asset) return [];

    const asset = {
      id: node.asset.id,
      mrn: node.asset.mrn,
      score: node.riskValue || 0,
      lastUpdated: node.lastUpdated ?? "",
      riskFactors: node.riskFactors,
      title: node.asset.name,
      iconId: node.asset.icon,
      tags: node.tags ? [...node.tags] : [],
      rating: node.rating,
    };

    // Manually add the platform tag
    if (node.platform) {
      asset.tags.push({
        key: "Platform",
        value: node.platform.title,
        __typename: "Tag",
      });
    }

    return [asset];
  });
  //     defaultSort: {
  //       field: FindingsOrderField.RiskValue,
  //       direction: OrderDirection.Desc,
  //     },
  //     validFields: [
  //       FindingsOrderField.AssetName,
  //       FindingsOrderField.LastUpdated,
  //       FindingsOrderField.RiskValue,
  //     ],
  //   });

  //   const { data, error, fetchMore, loading } = useGetAffectedAssetsQuery({
  //     variables: {
  //       scopeMrn: scope.mrn,
  //       first: 10,
  //       orderBy,
  //       filter,
  //     },
  //   });

  //   const findingsUnion = data?.findings;
  //   const findings =
  //     findingsUnion?.__typename === "FindingsConnection"
  //       ? findingsUnion
  //       : undefined;

  //   const assets = findings?.edges?.flatMap(({ node }) => {
  //     if (!node) return [];

  //     const asset = {
  //       id: node.asset?.id || "",
  //       mrn: node.asset?.mrn || "",
  //       score: node.riskValue || 0,
  //       lastUpdated: node.lastUpdated,
  //       riskFactors: node.riskFactors,
  //       title: node.asset?.name || "",
  //       iconId: node.asset?.icon,
  //       tags: node.asset?.tags,
  //       rating: node.rating,
  //     };

  //     return [asset];
  //   });

  return {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount: filteredTotalCount || 0,
    fetchMore,
    pageInfo: pageInfo,
    error,
    loading,
  };
}
