import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { FindingType, MqueryDocs, useGetCheckRowDataQuery } from "~/operations";
import { CheckFindingsCheckNode } from "~/pages/inventory/asset/types";
import { Markdown } from "../markdown";
import { ErrorBoundary } from "../error-boundary";
import { useFormattedAssetQuery } from "~/pages/findings/FindingOnAsset/hooks/useFormattedAssetQuery";
import { Code } from "../code";
import { Loading } from "../loading";
import { CheckResultIcon } from "./CheckResultIcon";

type FailedCheckProps = { assetMrn: string; check: CheckFindingsCheckNode };

export function FailedCheck({ assetMrn, check }: FailedCheckProps) {
  const { data, loading } = useGetCheckRowDataQuery({
    variables: {
      scopeMrn: assetMrn,
      first: 1,
      filter: {
        types: [FindingType.Check],
      },
      renderedAssetQueryInput: {
        assetMrn: assetMrn,
        queryMrn: check?.mrn || "",
      },
    },
  });

  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checkEdges = findingsUnion?.edges || [];
  const checks = checkEdges.flatMap((e) =>
    e?.node?.__typename === "CheckFinding" ? e.node : [],
  );

  const checkData = checks?.[0];
  const mquery = checkData?.mquery;

  const notFoundErrorMessage =
    data?.renderedAssetQueryData?.__typename === "NotFoundError"
      ? data.renderedAssetQueryData.message
      : null;
  const renderedData =
    data?.renderedAssetQueryData?.__typename === "RenderedAssetQueryData"
      ? data.renderedAssetQueryData
      : null;

  const { results } = useFormattedAssetQuery(renderedData, check?.state);

  if (loading) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          py: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Loading what="failed check" />
      </Grid>
    );
  }

  // extract remediation text from nested docs structure
  const getRemediationText = (remediations: MqueryDocs["remediations"]) => {
    if (
      remediations &&
      "entries" in remediations &&
      remediations.entries.length > 0
    ) {
      return remediations.entries.map((remedation) => (
        <Markdown key={remedation.id} source={remedation.desc} />
      ));
    }
    return null;
  };

  const remediationText = getRemediationText(mquery?.docs?.remediations);

  return (
    <Grid
      item
      xs={12}
      className="failed-check"
      sx={{
        py: 4,
      }}
    >
      <Typography
        variant="h5"
        component="h4"
        fontWeight={700}
        sx={{ mb: 2, px: 4 }}
      >
        {check?.title}
      </Typography>
      <Grid container item columnGap={6} sx={{ px: 4 }}>
        <Grid item xs>
          <Typography>{mquery?.docs?.desc || check?.title}</Typography>
        </Grid>
        <Grid item xs="auto">
          <CheckResultIcon
            result={check?.resultType}
            scoreState={check?.state}
          />
        </Grid>
      </Grid>
      <Grid container item sx={{ px: 4 }}>
        {(results || notFoundErrorMessage) && (
          <ListItem disableGutters>
            <ListItemText
              primary={
                <Grid container>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    mt={5}
                    mb={3}
                  >
                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <Typography
                          sx={{
                            display: "inline-block",
                            pr: 3,
                            fontWeight: 700,
                          }}
                        >
                          Result
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {notFoundErrorMessage && (
                      <Code copyButton className="ini">
                        {"[Query Error]\n" + notFoundErrorMessage}
                      </Code>
                    )}
                    {results}
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
        )}
        {remediationText && (
          <ErrorBoundary
            key={`${check?.mrn}-remediation`}
            FallbackComponent={RowErrorFallback}
          >
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="center"
                      mt={5}
                      mb={3}
                    >
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                          <Typography
                            sx={{
                              display: "inline-block",
                              pr: 3,
                              fontWeight: 700,
                            }}
                          >
                            Remediation
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {remediationText}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </ErrorBoundary>
        )}
      </Grid>
    </Grid>
  );
}

const RowErrorFallback = ({ error }: { error: Error }) => {
  console.error(error.message);
  return (
    <TableRow>
      <TableCell colSpan={5} sx={{ border: "none" }}></TableCell>
    </TableRow>
  );
};
