import {
  AdvisoryFinding,
  ReviewStatus,
  ScoreRating,
  ScoreResultType,
  ScoreState,
} from "~/operations";
import {
  CheckboxCell,
  ImpactCell,
  PublishedCell,
  RiskFactorsCell,
} from "~/components/Cells";
import { Box, CheckboxProps, TableCell, TableRow } from "@mui/material";
import { CheckResultByText } from "~/components/impact/ByText/ByText";
import { TitleCell } from "~/pages/inventory/components/Findings/FindingsTable/TitleCell";
import React from "react";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";
import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";
import { CheckFindingsCheckNode } from "~/pages/inventory/asset/types";
import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";
import { FindingTableTriageCell } from "~/pages/inventory/components/Findings/FindingsTable/FindingTableTriageCell";
import { FindingContext } from "~/pages/inventory/components/Findings/FindingsTable/findingTableUtils";

type FindingsTableRow = {
  finding: CVEFindingsNode | AdvisoryFindingsNode | CheckFindingsCheckNode;
  context: FindingContext;
  canSelect: boolean;
  onClick?: React.MouseEventHandler<HTMLTableRowElement> | undefined;
  isSelected?: boolean;
  onCheckboxSelect?: SwitchBaseProps["onChange"];
  onCheckboxClick: CheckboxProps["onClick"];
  onPendingExceptionClick?: (() => void) | undefined;
};

export const FindingsTableRow = ({
  finding,
  context,
  canSelect = false,
  onClick,
  isSelected = false,
  onCheckboxSelect,
  onCheckboxClick,
  onPendingExceptionClick,
}: FindingsTableRow) => {
  const isCheckFinding = finding.__typename === "CheckFinding";
  const isCveFinding = finding.__typename === "CveFinding";
  const isAdvisoryFinding = finding.__typename === "AdvisoryFinding";
  const isImminentExposure = context === "imminent_exposure";

  return (
    <TableRow
      id={finding.id}
      key={finding.id}
      onClick={onClick}
      className={finding.state === ScoreState.Open ? "" : "excepted"}
      sx={{
        cursor: "pointer",
        "&.MuiTableRow-root:hover .firewatch-chip": {
          backgroundColor: "background.lighter",
        },
      }}
    >
      {canSelect && (
        <CheckboxCell
          onClick={onCheckboxClick}
          onChange={onCheckboxSelect}
          checked={isSelected}
        />
      )}

      {isCheckFinding ? (
        <TableCell className="risk-cell" width="100px">
          <Box sx={{ display: "flex", gap: 1 }}>
            <CheckResultByText
              scoreResult={finding.resultType || ScoreResultType.Unknown}
              scoreState={finding.state || ScoreState.Open}
              rating={finding.rating || ScoreRating.Critical}
            />
          </Box>
        </TableCell>
      ) : (
        <ImpactCell
          data-testid={`${context}-impact`}
          rating={finding.rating}
          impact={finding.riskValue || 0}
          isActive={finding.state === ScoreState.Open}
          cellProps={{ width: "10%" }}
        />
      )}

      <TitleCell
        data-testid={`${context}-title`}
        id={finding.__typename !== "CheckFinding" ? finding.id : ""}
        title={finding.title}
        tags={finding.tags || []}
        state={finding.state}
        iconId={isAdvisoryFinding ? finding.iconId : undefined}
        isPendingException={
          finding?.exception?.reviewStatus === ReviewStatus.NotReviewed
        }
        onPendingExceptionClick={onPendingExceptionClick}
      />

      <RiskFactorsCell
        data-testid={`${context}-risk-factors`}
        riskFactors={finding.riskFactors}
      />

      {!isCheckFinding && !isImminentExposure && (
        <PublishedCell
          data-testid={`${context}-published`}
          date={(finding as AdvisoryFinding).publishedAt || ""}
        />
      )}

      {isCveFinding && isImminentExposure && (
        <FindingTableTriageCell cveFinding={finding} />
      )}
    </TableRow>
  );
};
