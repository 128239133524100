import { Flex } from "~/components/Flex";
import { ButtonProps, Typography } from "@mui/material";
import { KeysDropdownAutocomplete } from "../AutocompleteDropdown/KeysDropdownAutocomplete";
import { IndentIcon } from "~/components/icons";
import { ConditionValue, ConditionValues } from ".";
import { Operator } from "../Operator/Operator";
import { useConditionRow } from "../Condition/hooks";

export type ConditionRowProps = {
  selectionIndex: number;
  conditionIndex: number;
  onRemove: ButtonProps["onClick"];
  isReadonly: boolean;
};

export const ConditionRow = ({
  conditionIndex,
  selectionIndex,
  onRemove,
  isReadonly,
}: ConditionRowProps) => {
  const {
    showIncludeLabel,
    showConditionOperator,
    showConditionKeyLabel,
    isKeySelected,
    isDictionaryKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions,
    selectedConditionKeyLabel,
    conditionValuesFieldName,
    conditionFormDictionaryKey,
    handleConditionValueRemove,
    handleConditionKeyRemove,
    handleKeyChange,
    handleValuesItemClick,
    handleDropdownSearchFieldKeyUp,
    handleDropdownSearchFieldChange,
    isLoading,
    isValuesDropdownOpen,
    handleValuesDropdownOpen,
    handleValuesDropdownClose,
    handleKeyValueKeyClick,
    handleKeyValueValueClick,
  } = useConditionRow({
    selectionIndex,
    conditionIndex,
    isReadonly,
  });

  return (
    <Flex
      gap={2}
      alignItems="flex-start"
      sx={{
        "&:hover .delete-condition-button": {
          visibility: "visible",
        },
      }}
    >
      {showIncludeLabel && (
        <Typography sx={{ fontWeight: 700, p: 1 }}>INCLUDE:</Typography>
      )}
      {showConditionOperator && (
        <Flex alignItems="center" gap={2} ml={8}>
          <IndentIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          <Operator
            selectionIndex={selectionIndex}
            conditionIndex={conditionIndex}
            isReadonly={isReadonly}
            type="condition"
          />
        </Flex>
      )}
      {showConditionKeyLabel ? (
        <ConditionValue
          value={selectedConditionKeyLabel || ""}
          onClick={handleConditionKeyRemove}
          isReadonly={isReadonly}
        />
      ) : (
        conditionValues.length === 0 &&
        !isReadonly && (
          <KeysDropdownAutocomplete
            name={`selections.${selectionIndex}.conditions.${conditionIndex}.formKey`}
            groups={conditionKeys}
            onChange={handleKeyChange}
          />
        )
      )}
      {isKeySelected && (
        <Operator
          selectionIndex={selectionIndex}
          conditionIndex={conditionIndex}
          isReadonly={isReadonly}
        />
      )}
      <ConditionValues
        values={conditionValues}
        options={conditionValuesOptions}
        showRemoveConditionButton={conditionIndex !== 0 && !isReadonly}
        onRemove={onRemove}
        isKeySelected={isKeySelected}
        isDictionaryKeySelected={isDictionaryKeySelected}
        onValueRemove={handleConditionValueRemove}
        name={conditionValuesFieldName}
        conditionFormDictionaryKey={conditionFormDictionaryKey}
        isReadonly={isReadonly}
        isLoading={isLoading}
        onDropdownSearchFieldKeyUp={handleDropdownSearchFieldKeyUp}
        onDropdownSearchFieldChange={handleDropdownSearchFieldChange}
        onItemClick={handleValuesItemClick}
        handleKeyValueKeyClick={handleKeyValueKeyClick}
        handleKeyValueValueClick={handleKeyValueValueClick}
        isValuesDropdownOpen={isValuesDropdownOpen}
        onValuesDropdownOpen={handleValuesDropdownOpen}
        onValuesDropdownClose={handleValuesDropdownClose}
      />
    </Flex>
  );
};
