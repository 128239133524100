import { Box, Button, CircularProgress } from "@mui/material";

type RiskFactorsActionRowProps = {
  isDefaults: boolean;
  onResetToDefault?: () => void;
  isValid: boolean;
  isDirty: boolean;
  onCancel: () => void;
  isSubmitting: boolean;
};

export const RiskFactorsActionRow = ({
  isDefaults,
  onResetToDefault,
  isValid,
  isDirty,
  onCancel,
  isSubmitting,
}: RiskFactorsActionRowProps) => {
  return (
    <Box
      className="risk-factors-config-form-actions"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
        position: "sticky",
        top: 70,
        p: 1,
        borderRadius: 1,
        bgcolor: "background.paper",
        zIndex: 1,
      }}
    >
      {onResetToDefault && !isSubmitting && (
        <Button variant="text" disabled={isDefaults} onClick={onResetToDefault}>
          Reset to defaults
        </Button>
      )}
      {!isSubmitting && (
        <Button
          variant="outlined"
          color="primary"
          disabled={!isValid || !isDirty}
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!isValid || !isDirty || isSubmitting}
      >
        {isSubmitting && (
          <CircularProgress
            color="secondary"
            size={20}
            sx={{ position: "absolute" }}
          />
        )}
        Save Changes
      </Button>
    </Box>
  );
};
