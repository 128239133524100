import { Box, Skeleton } from "@mui/material";
import { motion } from "framer-motion";

export function SkeletonLines() {
  // Generate random width skeletons
  const skeletonLines = Array.from({ length: 35 }, (_, i) => ({
    width: Math.floor(Math.random() * 60) + 40, // Random width between 40% and 100%
    indent: Math.floor(Math.random() * 4) * 20, // Random indentation (0, 20, 40, 60px)
  }));

  return (
    <Box mt={2}>
      {skeletonLines.map((line, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1.2, // fade in duration
            delay: index * 0.05, // Stagger the delay of fade in of skeleton lines
            ease: "easeInOut",
          }}
        >
          <Box sx={{ display: "flex", mb: 1 }}>
            <Box sx={{ width: line.indent }} />
            <Skeleton
              variant="text"
              width={`${line.width}%`}
              height={24}
              animation="wave"
            />
          </Box>
        </motion.div>
      ))}
    </Box>
  );
}
