import { Flex } from "~/components/Flex";
import { ButtonProps, IconButton } from "@mui/material";
import { KeysDropdownAutocomplete } from "../autocomplete-dropdown/KeysDropdownAutocomplete";
import { IndentIcon } from "~/components/icons";
import { Operator } from "../Operator/Operator";
import { Close } from "@mui/icons-material";
import { useConditionRow } from "./hooks/useConditionRow";
import { ConditionValue } from "./ConditionValue";
import { ConditionValues } from "./ConditionValues";

export type ConditionRowProps = {
  fieldId: string;
  selectionIndex: number;
  conditionIndex: number;
  onRemove: ButtonProps["onClick"];
  isReadonly: boolean;
};

export const ConditionRow = ({
  fieldId,
  conditionIndex,
  selectionIndex,
  onRemove,
  isReadonly,
}: ConditionRowProps) => {
  const {
    showConditionOperator,
    showConditionKeyLabel,
    showRemoveConditionButton,
    isKeySelected,
    isDictionaryKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions,
    selectedConditionKeyLabel,
    conditionValuesFieldName,
    conditionFormDictionaryKey,
    handleConditionValueRemove,
    handleConditionKeyRemove,
    handleKeyChange,
    handleValuesItemClick,
    handleDropdownSearchFieldKeyUp,
    handleDropdownSearchFieldChange,
    isLoading,
    isValuesDropdownOpen,
    handleValuesDropdownOpen,
    handleValuesDropdownClose,
    handleKeyValueKeyClick,
    handleKeyValueValueClick,
  } = useConditionRow({
    fieldId,
    selectionIndex,
    conditionIndex,
    isReadonly,
  });

  return (
    <Flex>
      <Flex gap={2} alignItems="flex-start" flexWrap="wrap">
        {showConditionOperator && (
          <Flex alignItems="center" gap={2} ml={6}>
            <IndentIcon
              sx={{ color: (theme) => theme.palette.text.disabled }}
            />
            <Operator
              fieldId={fieldId}
              selectionIndex={selectionIndex}
              conditionIndex={conditionIndex}
              isReadonly
              type="condition"
            />
          </Flex>
        )}
        {showConditionKeyLabel ? (
          <ConditionValue
            value={selectedConditionKeyLabel || ""}
            onClick={handleConditionKeyRemove}
            isReadonly={false}
          />
        ) : (
          conditionValues.length === 0 &&
          !isReadonly && (
            <KeysDropdownAutocomplete
              name={`${fieldId}.selections.${selectionIndex}.conditions.${conditionIndex}.formKey`}
              groups={conditionKeys}
              onChange={handleKeyChange}
            />
          )
        )}
        {isKeySelected && (
          <Operator
            fieldId={fieldId}
            selectionIndex={selectionIndex}
            conditionIndex={conditionIndex}
            isReadonly
          />
        )}
        <ConditionValues
          values={conditionValues}
          options={conditionValuesOptions}
          isKeySelected={isKeySelected}
          isDictionaryKeySelected={isDictionaryKeySelected}
          onValueRemove={handleConditionValueRemove}
          name={conditionValuesFieldName}
          conditionFormDictionaryKey={conditionFormDictionaryKey}
          isReadonly={isReadonly}
          isLoading={isLoading}
          onDropdownSearchFieldKeyUp={handleDropdownSearchFieldKeyUp}
          onDropdownSearchFieldChange={handleDropdownSearchFieldChange}
          onItemClick={handleValuesItemClick}
          handleKeyValueKeyClick={handleKeyValueKeyClick}
          handleKeyValueValueClick={handleKeyValueValueClick}
          isValuesDropdownOpen={isValuesDropdownOpen}
          onValuesDropdownOpen={handleValuesDropdownOpen}
          onValuesDropdownClose={handleValuesDropdownClose}
        />
      </Flex>
      {showRemoveConditionButton && (
        <Flex
          sx={{
            flex: "1 0 auto",
            justifyContent: "flex-end",
          }}
          className="delete-condition-button"
          alignItems="flex-start"
        >
          <IconButton
            onClick={onRemove}
            sx={{ color: (theme) => theme.palette.error.main }}
          >
            <Close />
          </IconButton>
        </Flex>
      )}
    </Flex>
  );
};
