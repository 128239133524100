import { Route, Routes } from "react-router-dom";
import { Space } from "~/lib/types";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { SpaceDashboard } from "~/pages/dashboard-space";
import { ImminentExposuresPage } from "~/pages/space/Dashboards/components/ImminentExposuresPage/ImminentExposuresPage";

type DashboardRoutesProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
};

export function DashboardRoutes({ space, scope }: DashboardRoutesProps) {
  return (
    <Routes>
      <Route index element={<SpaceDashboard space={space} scope={scope} />} />
      <Route
        path="imminent-exposures"
        element={<ImminentExposuresPage space={space} scope={scope} />}
      />
    </Routes>
  );
}
